@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;
@use '_variables' as nav;

.nav-widget-actions {
  @include m.flex(end, center);
  height: 48px;
  padding-left: unset;

  .nav-action-button {
    justify-content: center;
    height: v.$button-size;
    min-width: v.$button-size;
    margin: 0;
    padding: 0 8px;
    background: unset;
    color: var(--nav-color);
    border-radius: var(--input-border-radius);
    cursor: pointer;
    box-sizing: border-box;

    @include m.bp(sm) {
      height: v.$button-size + 4px;
      min-width: v.$button-size + 4px;
    }

    &--dropdown {
      width: v.$button-size + 8px;
      padding: 0;
    }

    .icon {
      color: var(--nav-color);
      transition: 0.24s;
      font-size: 1.28rem !important;

      @include m.bp(sm) {
        font-size: 1.4rem !important;
      }
    }

    > *:not(.site-picker) {
      transition: background nav.$menu-item-transition, color nav.$menu-item-transition;
    }

    /* states */
    @include m.interaction {
      background: var(--nav-accent);

      .icon {
        color: var(--nav-accent-color) !important;
      }

      /* Select2 specific element */
      .select2-selection {
        outline: unset;
      }

      .select2-selection__arrow b {
        border-top-color: var(--mono-4);
      }
    }
  }

  /* Site Picker */
  .site-picker {
    position: relative;

    select {
      visibility: hidden;
    }

    .select2 {
      width: 100% !important;

      .selection .select2-selection {
        border: unset;
        background: unset;
        padding: 8px 16px 8px 8px;

        &__rendered {
          .icon {
            font-size: 1.4rem;
          }

          .label {
            display: none;
          }
        }
      }

      &-selection__arrow b {
        border-top-color: var(--mono-5);
      }
    }
  }
}

.site-picker-dropdown {
  @include m.position(fixed, 64px, 0, null, null);
  width: 320px;
  margin: 0 8px;
  z-index: v.$z-action-nav-dropdown;
  pointer-events: none;

  @include m.bp(sm) {
    top: var(--nav-height-sm);
    width: 400px;
  }

  @include m.bp(md) {
    top: var(--nav-height-sm);
  }

  @include m.bp(lg) {
    top: var(--nav-height-lg);
  }

  .select2-container {
    left: unset !important;
    top: 0 !important;
    right: 0;
    min-width: 160px;
    pointer-events: initial;

    .select2-dropdown {
      border-radius: calc(var(--input-border-radius) / 2);
      box-shadow: 0 0 8px rgb(0 0 0 / 20%);
      border: unset;
      background: var(--nav-background);
      color: var(--nav-color);
      overflow: hidden;

      .select2-results__option--selected {
        background: rgba(var(--nav-accent-rgb), 0.24);
      }

      .select2-results__option--highlighted {
        background: var(--nav-accent);
      }


      @include m.bp(md) {
        border-radius: 0 0 calc(var(--input-border-radius) / 2) calc(var(--input-border-radius) / 2);
        box-shadow: 0 6px 6px rgb(0 0 0 / 20%);
      }
    }
  }

  &[data-dark-mode] {
    .select2-dropdown {
      color: var(--nav-accent-color);
    }
  }
}