@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.table, *:has(> table) {
  @include m.hide-scrollbars;
  overflow-x: scroll;
  max-width: 100%;
}

[data-dark-mode] table:not(.widget-table) {
  color: var(--text-color-light);
  border: 1px solid var(--mono-8);

  thead {
    background: var(--mono-7);
  }

  tbody {
    background: var(--mono-9);

    tr:nth-child(even) {
      background: var(--mono-8);
    }
  }
}

table:not(.widget-table), [data-light-mode] table:not(.widget-table) {
  width: 100%;
  margin: 16px 0;
  border-spacing: 0;
  overflow: hidden;
  color: var(--text-color-dark);
  border-radius: var(--shape-border-radius);
  border: 1px solid var(--mono-2);

  thead {
    background: var(--mono-4);
  }

  tbody {
    background: var(--mono-1);

    tr:nth-child(even) {
      background: var(--mono-2);
    }
  }

  th, td {
    height: 36px;
    vertical-align: middle;
    text-align: left;
    color: inherit;
    padding: 0 12px;

    @include m.bp(sm) {
      height: 40px;
    }
  }

  th {
    font-weight: v.$font-bold;
    font-style: normal;
  }

  /* Rounded corners */
  @mixin table-top-radius {
    &:first-child {
      &:first-child > *:first-child {
        border-top-left-radius: calc(var(--shape-border-radius) - 1px);
      }

      &:last-child > *:last-child {
        border-top-right-radius: calc(var(--shape-border-radius) - 1px);
      }
    }
  }

  @mixin table-bottom-radius {
    &:last-child {
      > *:first-child {
        border-bottom-left-radius: calc(var(--shape-border-radius) - 1px);
      }

      > *:last-child {
        border-bottom-right-radius: calc(var(--shape-border-radius) - 1px);
      }
    }
  }

  thead {
    &:last-child {
      @include table-bottom-radius;
    }

    tr {
      @include table-top-radius;
    }
  }

  tbody tr {
    @include table-top-radius;
    @include table-bottom-radius;
  }

  thead + tbody tr {
    &:first-child {
      > *:first-child, > *:last-child {
        border-radius: unset !important;
      }
    }
  }
}