@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

/*
 * Buttons
 */

%button {
  @include m.flex(center, center, row, inline-flex);
  flex: 0 0 auto;
  position: relative;
  min-height: 44px;
  min-width: 44px;
  margin: 0;
  padding: 0 12px;
  gap: 8px;
  color: var(--button-main-label);
  border: 1px solid transparent;
  border-radius: var(--input-border-radius);
  font-family: var(--text-font), sans-serif;
  font-weight: v.$font-medium;
  letter-spacing: 0.98px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  transition: v.$button-transition;

  /* Prevent accidental text selection */
  & * {
    pointer-events: none;
  }

  &:active {
    user-select: none;
  }

  &:has(.loading-indicator[style*="display: block"]) {
    pointer-events: none;
    color: var(--mono-4);
    background: var(--state-disabled);
    border: 1px solid var(--state-disabled);
  }

  .loading-indicator.icon {
    display: none;
    font-size: 1.4em;

    &[style*="display: block"] ~ * {
      display: none;
    }
  }

  .label {
    color: inherit;
    font-weight: inherit;
  }

  .icon {
    flex: 0;
    color: inherit;
    font-size: 1.12em;
    opacity: 0.8;

    &:first-child:last-child {
      font-size: 1em;
    }

    &--after {
      order: 2;
    }
  }

  .flag {
    width: 1.12em;
  }
}

.button {
  @extend %button;

  @include m.generateThemeColors() {

    &--#{m.$gtc-color} {
      color: var(--#{m.$gtc-type}-#{m.$gtc-color}-label);
      background: var(--#{m.$gtc-type}-#{m.$gtc-color});
      border-color: var(--#{m.$gtc-type}-#{m.$gtc-color});

      @include m.interaction {
        background: var(--#{m.$gtc-type}-#{m.$gtc-color}-active);
        border-color: var(--#{m.$gtc-type}-#{m.$gtc-color}-active);
      }
    }

    /* Outline button */
    &--outline#{&}--#{m.$gtc-color} {
      border-color: var(--#{m.$gtc-type}-#{m.$gtc-color});
      color: var(--#{m.$gtc-type}-#{m.$gtc-color}-outline-label);
      background: unset;

      @include m.interaction() {
        background: rgba(var(--#{m.$gtc-type}-#{m.$gtc-color}-rgb), 0.08);
        color: var(--#{m.$gtc-type}-#{m.$gtc-color});
        border-color: var(--#{m.$gtc-type}-#{m.$gtc-color}-active);
      }

      @include m.interaction(active) {
        background: rgba(var(--#{m.$gtc-type}-#{m.$gtc-color}-rgb), 0.4);
      }
    }

    /* Text buttons */
    &--text#{&}--#{m.$gtc-color} {
      color: var(--#{m.$gtc-type}-#{m.$gtc-color}-outline-label);

      @include m.interaction() {
        color: var(--#{m.$gtc-type}-#{m.$gtc-color}-active);
      }
    }
  }


  /*
   * Modifiers
   */

  &--link {
    padding: unset;
    color: var(--link-color);
    transition: v.$button-transition;
    min-height: unset;

    @include m.interaction() {
      color: var(--link-color-active);
    }
  }

  &--disabled, &--disabled:hover, &--disabled:focus, &--disabled:focus-within {
    background: var(--mono-4);
    border-color: var(--mono-4);
    cursor: not-allowed;

    .label, .icon {
      color: var(--state-disabled);
      user-select: none;
    }
  }

  &--text {
    background: unset !important;
    border: unset;
  }

  &--xs {
    min-height: 32px;
    min-width: 32px;
  }

  /* make the button full width */
  &--full-width {
    width: 100%;
    grid-column: 1 / -1;
  }

  /* hide labels and icons on certain breakpoints */
  @include m.bp(null, sm) {
    &--hide-label-xs .label {
      display: none;
    }

    &--hide-icon-xs .icon {
      display: none;
    }
  }

  @include m.bp(sm) {
    &--hide-label-sm .label {
      display: none;
    }

    &--hide-icon-sm .icon {
      display: none;
    }
  }
}

/* Temporary fix for button logic */
[data-dark-mode] .button--outline {
  .icon, .label {
    color: var(--text-color-light);
  }
}