@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;
@use 'fonts';

.cookie-banner {
  @include m.position(fixed, null, 0, 0, 0);
  @include m.grid(6, 12, 12, 12, 1fr);
  background: var(--notification-background);
  box-shadow: v.$shadow-notification;
  padding: 0 16px calc(var(--nav-height-xs) + 16px);
  border-radius: var(--shape-border-radius) var(--shape-border-radius) 0 0;
  transform: translateX(0);
  transition: transform 0.56s cubic-bezier(0.36, 0, 0.66, -0.56);
  z-index: v.$z-cookie-banner;

  @include m.bp(sm) {
    top: calc(var(--nav-height-sm) + 24px);
    right: 12px;
    bottom: unset;
    left: unset;
    max-width: 480px;
    padding: 0 16px 16px;
    border-radius: var(--shape-border-radius);
  }

  @include m.bp(xl) {
    top: calc(var(--nav-height-lg) + 24px);
    max-width: 640px;
  }

  // Inactive state
  &[data-notification='inactive'] {
    bottom: 0;
    transform: translateY(100%);

    @include m.bp(sm) {
      bottom: unset;
      transform: translateX(600px);
    }

    @include m.bp(xl) {
      bottom: unset;
      transform: translateX(800px);
    }
  }

  .heading {
    grid-column: 1 / -1;
    margin: 16px 0;

    @include m.bp(sm) {
      margin: 0.4rem 0 1.2rem;
    }

    &__title {
      @extend h5;
      color: var(--notification-text-color) !important;
      padding-top: 0.2em;
    }

    p {
      font-size: 0.88rem;
      margin-top: 4px;
      color: var(--notification-text-color);
    }
  }

  &__options {
    @include m.flex(between, center);
    grid-column: 1 / -1;
    gap: 16px;

    .button {
      flex: 1 1 auto;

      &--outline .label {
        color: var(--notification-text-color);
      }
    }
  }
}