@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

// Transition shortcuts
$overlay-out: v.$overlay-transition-time v.$overlay-transition-out;
$overlay-in: v.$overlay-transition-time 1ms v.$overlay-transition-in;
$overlay-delayed-in: v.$overlay-transition-time 50ms v.$overlay-transition-in;

%page-overlay {
  @include m.position(fixed, 0, 0, 0, 0);
  display: flex;
  width: 100vw;
  height: 0;
  margin: 0;
  padding: 0;
  border: unset;
  background: v.$overlay-backdrop-hidden;
  backdrop-filter: blur(0);
  box-sizing: border-box;
  opacity: 0;
  transition: background $overlay-out, backdrop-filter $overlay-out, height 1ms v.$overlay-transition-time, opacity 1ms v.$overlay-transition-time, height 1ms v.$overlay-transition-time;
  will-change: transform;

  &[data-overlay-state='visible'] {
    background: v.$overlay-backdrop-visible;
    backdrop-filter: blur(2px);
    height: 100%;
    opacity: 1;
    transition: background $overlay-delayed-in, backdrop-filter $overlay-delayed-in;

    > * {
      opacity: 1;
      transform: translateY(0);
      transition: transform $overlay-delayed-in, opacity $overlay-delayed-in;
    }
  }

  &[data-overlay-behind-nav] {
    z-index: v.$z-overlay-behind-nav;

    @include m.bp(null, sm) {
      border-radius: unset;
    }

    @include m.bp(md) {
      top: var(--nav-height-sm);
    }

    @include m.bp(lg) {
      top: var(--nav-height-lg);
    }

    &[data-overlay-state='visible'] {
      height: calc(100% - var(--nav-height-xs));

      @include m.bp(md) {
        height: calc(100% - var(--nav-height-sm));
      }

      @include m.bp(lg) {
        height: calc(100% - var(--nav-height-lg));
      }
    }
  }

  > * {
    margin: auto 0 0;
    transform: translateY(100%);
    transition: transform $overlay-out, opacity $overlay-out;

    @include m.bp(null, sm) {
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.page-modal {
  @extend %page-overlay;
  @include m.flex(center, stretch);
  z-index: v.$z-modal-overlay;

  @include m.bp(sm) {
    align-items: center;
  }

  &--align-top {
    align-items: flex-start;
  }

  > * {

    @include m.bp(sm) {
      opacity: 0;
      transform: translateY(64px);
      margin: map-get(v.$section-padding-sizes, sm);
    }

    @include m.bp(md) {
      margin: map-get(v.$section-padding-sizes, md);
    }

    @include m.bp(lg) {
      margin: map-get(v.$section-padding-sizes, lg);
    }
  }
}

.page-side-menu {
  @extend %page-overlay;
  z-index: v.$z-side-menu-overlay;

  @include m.bp(sm) {
    top: 0;
    justify-content: flex-end;
  }

  > * {
    border: unset;
    border-radius: unset;

    @include m.bp(sm) {
      height: 100%;
      margin-left: auto;
      transform: translateX(100%);
    }
  }

  @include m.bp(sm) {

    &[data-overlay-state='visible'] {
      @include m.flex(end, stretch);
    }

    &:not([data-size]) > *, &[data-size='md'] > * {
      max-width: 640px;
    }

    &[data-size='sm'] > * {
      max-width: 480px;
    }

    &[data-size='lg'] {
      right: -800px;
      max-width: 800px;
    }
  }
}