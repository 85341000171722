@use '../../../../../resources/scss/mixins' as m;

@font-face {
  font-family: 'Zona Pro Subset';
  src: url('/themes/helder-helder/assets/fonts/zonapro-regular-subset.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.footer-brand-bar {
  @include m.flex(center, center, column);
  @include m.section-padding-sizes();
  gap: 32px;
  padding-top: 32px;
  padding-bottom: calc(32px + var(--tab-bar-height-xs));
  background: rgba(0, 0, 0, 0.32);
  text-align: center;

  &:not([data-dark-mode]) {
    background: rgba(255, 255, 255, 0.32);
  }

  @include m.bp(sm) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: calc(16px + var(--tab-bar-height-sm));
  }

  @include m.bp(md) {
    padding-bottom: 16px;
  }

  @include m.bp(lg) {
    gap: 16px;
  }

  &__copyright {
    flex: 0 0 auto;
    margin: 0;
  }

  .helder-badge {
    @include m.flex(center, center);
    gap: 12px;
    flex: 0 0 auto;
    width: auto;
    margin: 0;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    font-family: 'Zona Pro Subset', sans-serif;
    transition: transform 0.32s cubic-bezier(0.29, 2.06, 0.5, 0.86);

    @include m.interaction() {
      transform: scale(0.92);
      color: inherit;
    }

    @include m.bp(sm) {
      margin-left: auto;
    }

    &__label {
      white-space: nowrap;
      font-family: inherit;
      font-size: 0.92em;
    }

    &__image {
      @include m.flex(center, center);
      border-radius: unset;

      .image {
        height: 22px;
        object-fit: contain;
      }
    }
  }
}