@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/* Grid list styling */
.grid-list {
  display: grid;
  justify-content: start;
  margin: 0;
  list-style-type: none;

  @include m.bp(sm) {
    grid-column-gap: 16px;
    grid-template-columns: auto auto;
    grid-row-gap: 8px;
  }

  &__label {
    grid-column: 1;
    font-weight: v.$font-semi-bold;
    font-size: 0.88rem;

    @include m.bp(sm) {
      font-size: 1rem;
    }
  }

  &__value {
    grid-column: 1;
    margin-bottom: 8px;

    @include m.bp(sm) {
      grid-column: auto / -1;
      margin-bottom: 0;
    }
  }

  &__empty-row {
    grid-column: 1 / -1;
    height: 4px;

    @include m.bp(sm) {
      height: 12px;
    }
  }
}

/* Address list styling */
.address-list {
  margin: 0;
  padding: 0;

  &__item {
    @include m.flex(start, start, column);
    gap: 2px;
    padding: 8px 12px;
    background: var(--list-row-odd);
    list-style-type: none;

    @include m.bp(sm) {
      gap: 4px;
      padding: 12px 24px;
    }

    &:nth-child(even) {
      background: var(--list-row-even);
    }

    * {
      margin: 0;
      padding: 0;
    }
  }
}