@use '../../../../../../resources/scss/variables' as v;

/*
 * This file is based on Select2 v4.0.0
 * Source: https://select2.org/
 */

.select2-dropdown {
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  background-color: var(--input-field-background);
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
  z-index: v.$z-dropdown;
}

.select2-results {
  display: block;

  &__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__option {
    padding: 8px;
    user-select: none;
    -webkit-user-select: none;

    .icon {
      margin-right: 8px;
    }

    &--selectable {
      cursor: pointer;
    }
  }
}

.select2-container--open .select2-dropdown {
  left: 0;

  &--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}
