@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/*
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight);
  line-height: calc(var(--heading-line-height) * 1.28);
  letter-spacing: calc(var(--heading-letter-spacing) * 1px);
  color: var(--heading-color-dark);
  grid-column: 1 / -1;
  word-wrap: break-word;
}

h1 {
  font-size: calc(2.2rem * var(--heading-size));
  padding: 2.2rem 0 0.4rem;
}

h2 {
  font-size: calc(2rem * var(--heading-size));
  padding: 1.8rem 0 0.3rem;
}

h3 {
  font-size: calc(1.6rem * var(--heading-size));
  padding: 1.4rem 0 0.2rem;
}

h4 {
  font-size: calc(1.4rem * var(--heading-size));
  padding: 1.2rem 0 0.1rem;
}

h5 {
  font-size: calc(1.2rem * var(--heading-size));
  padding: 1rem 0 0;
}

h6 {
  font-size: calc(1rem * var(--heading-size));
  padding: 0.8rem 0 0;
}

/*
 * Light text modifiers for dark page backgrounds
 */

body[data-dark-mode] {
  h1, h2, h3, h4, h5, h6 {
    color: var(--heading-color-light);
  }
}


//////////////////////////////////////////////////
//
//  Text
//
//////////////////////////////////////////////////

p, cite, b, strong, i, em, pre, span,
address, del, ins, figure, figcaption, blockquote, q, a,
th, td, ul, ol, li,
label, fieldset, legend, input, textarea, select, button {
  font-family: var(--text-font);
  font-weight: var(--text-font-weight);
  font-size: 1rem;
  line-height: calc(var(--text-line-height) * 1.6);
  letter-spacing: calc(var(--text-letter-spacing) * 1px);
  word-wrap: break-word;
}

p, cite, b, strong, i, em, address, del, ins, blockquote, q, a {
  margin: 0.48rem 0 0;
}

p {
  grid-column: 1 / -1;
}

blockquote, q, th, i {
  font-style: italic;
}

blockquote, q, th {
  color: var(--mono-5);
}

a:not(.button, .tag, .menu-item) {
  color: var(--link-color);
  font-weight: v.$font-semi-bold;
  transition: 0.4s;

  &:hover, &:focus {
    color: var(--link-color-active);
  }

  &:visited {
    color: var(--link-color-visited);

    &:hover, &:focus {
      color: var(--link-color-active);
    }
  }
}

b, strong {
  font-weight: v.$font-bold;
}

i, em {
  font-style: italic;
}

ul, ol {
  padding-left: 20px;
  margin: 1rem 0 0;
}

sup {
  vertical-align: super;
  font-size: 0.64rem;
}

sub {
  vertical-align: sub;
  font-size: 0.64rem;
}


//////////////////////////////////////////////////
//
//  Custom text classes
//
//////////////////////////////////////////////////

.note {
  font-size: 0.88rem;
  color: var(--mono-6);
}