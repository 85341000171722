@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.form {

  &--hidden {
    display: none;
    visibility: hidden;
  }

  label, input, textarea, select, option {
    accent-color: var(--brand-main);
  }

  textarea {
    height: 100%;
    box-sizing: border-box;
  }

  /* Submit button */
  .button {
    grid-column: 1 / -1;
    margin: 1rem 0 0;
  }
}

.heading {
  margin-top: 16px;
}

.form-group {
  @include m.grid;
  grid-auto-flow: dense;
  grid-column: 1 / -1;
  align-items: start;

  &--submit {
    align-items: center;
  }

  .button {
    @include m.bp(sm) {
      grid-column: span 6 / -1;
    }

    @include m.bp(xl) {
      grid-column: span 9 / -1;
    }
  }
}

.form-comment {
  grid-column: 1 / -1;
  margin: -16px 0 16px;

  @include m.bp(sm) {
    grid-column: 1 / span 6;
    margin: unset;
  }

  @include m.bp(xl) {
    grid-column: 1 / span 9;
  }
}

.input-group {
  grid-column: 1 / -1;
  flex: 1 1 auto;
  margin: 0.8rem 0 0;

  &--stretch {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  &--borderless &__container {
    background: unset;
    border: unset;
    padding: 0;
  }

  &--file-upload {
    height: calc(100% - 24px);
  }

  &--scroll-list &__container {

    @include m.bp(sm) {
      max-height: 496px;
      overflow-y: scroll;
      padding-right: 20px;
    }
  }

  /* Alignment */
  @include m.bp(sm) {
    &--left {
      grid-column: 1 / span 6;
    }

    &--right {
      grid-column: span 6 / -1;
    }
  }

  @include m.bp(lg) {
    &--left {
      grid-column: 1 / span 9;
    }

    &--right {
      grid-column: span 9 / -1;
    }
  }

  @for $row from 2 to 10 {
    &--row-#{$row} {
      grid-row: span $row;
    }
  }

  /* Textarea */
  &--resize-both .textarea {
    resize: both;
  }

  &--resize-vertical .textarea {
    resize: vertical;
  }

  &--resize-horizontal .textarea {
    resize: horizontal;
  }

  /* alignment for checkboxes and radio buttons */
  &--inline {
    @include m.flex(start, center);
    align-items: center;
    gap: 8px;
    margin: unset;
    padding: 8px 0;

    label {
      grid-area: label;
    }

    input {
      grid-area: input;
    }

    .label__comment {
      grid-area: comment;
    }
  }

  /* For multiple field parts like prefixes and suffixes */
  &__field {
    @include m.flex(between, center);
    position: relative;
  }

  /* Container for fieldset groups */
  &__container {
    @include m.flex(start, stretch, column);
    margin: 8px 0 0;
    padding: 8px 12px;
    gap: 8px;
    border: 1px solid var(--input-field-border);
    border-radius: var(--input-border-radius);
    background: var(--input-field-background);

    @include m.bp(sm) {
      height: calc(100% - 8px);
      box-sizing: border-box;
    }

    @include m.bp(lg) {
      gap: 16px;
    }

    @include m.bp(xl) {
      padding: 18px 16px;
    }

    .input-group {
      margin: unset;
    }
  }

  /* Address form group */
  &--address {
    @include m.flex(between, start);
    flex-wrap: wrap;
    gap: 0 8px;
    margin: 0;

    @include m.bp(sm) {
      gap: 0 16px;
    }

    @include m.bp(xl) {
      gap: 0 24px;
    }
  }

  &--address & {
    &__street, &__postal-code, &__city {
      flex: 1 0 100%;
      margin: 16px 0 0;

      @include m.bp(sm) {
        flex: 0 0 calc(50% - 8px);
        margin: 24px 0 0;
      }

      @include m.bp(xl) {
        flex: 0 0 calc(50% - 12px);
      }
    }

    &__house-number, &__house-number-addition {
      flex: 1 0 100%;
      margin: 16px 0 0;

      @include m.bp(sm) {
        flex: 0 0 calc(25% - 12px);
        margin: 24px 0 0;
      }

      @include m.bp(xl) {
        flex: 0 0 calc(25% - 18px);
      }
    }

    &__country {
      flex: 1 0 100%;
    }
  }

  /* Range input fiter */
  &--range-filter {
    @include m.flex(between, center);
    gap: 8px;

    @include m.bp(sm) {
      gap: 16px;
    }
  }

  /* Disabled modifier */
  &--disabled {
    input, textarea {
      pointer-events: none;
      background: var(--mono-4);
    }
  }

  /* Input styling for inputs with side buttons */
  &--buttons {
    @include m.flex(center, center);
    gap: 2px;

    .input-group__field {
      flex: 0 0 44px;

      input {
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
          -webkit-appearance: none;
        }
      }
    }
  }
}

/* File upload field */
.file-upload {
  @include m.flex(center, center);
  position: relative;
  flex-direction: column;
  margin: 8px 0 0;
  padding: 16px;
  border: 1px dashed var(--input-field-border);
  background: var(--input-field-background);
  text-align: center;
  transition: background 0.24s, color 0.24s, height 0.24s;

  @include m.bp(sm) {
    height: calc(100% - 88px);
    padding: 24px;
  }

  &[data-state='empty'] & {
    &__step {
      &--drop, &--done {
        display: none;
      }
    }
  }

  &[data-state='hover'] {
    background: rgba(var(--button-main-rgb), 0.12);
  }

  &[data-state='hover'] & {
    &__step {
      &--empty, &--done {
        display: none;
      }
    }
  }

  &[data-state='done'] & {
    &__step {
      &--empty, &--drop {
        display: none;
      }
    }
  }

  &__step {
    @include m.flex(center, center, column);
    pointer-events: none;

    &__icon {
      color: var(--button-main);
      font-size: 2.4rem;
    }

    &__explainer {
      margin: 16px 0 0;
      font-weight: v.$font-regular;

      b {
        font-weight: v.$font-semi-bold;
        color: var(--input-field-color);
      }

      &--touch {
        display: none;
      }

      &__link {
        color: var(--link-color);
        text-decoration: underline;
        font-weight: v.$font-semi-bold;
      }
    }
  }

  &__files {
    width: 100%;
    max-width: 560px;
    z-index: 100;

    &__file {
      @include m.flex(between, center);
      width: 100%;
      height: 48px;
      padding: 0 0 0 16px;
      margin: 0 0 8px;
      background: rgba(var(--button-main-rgb), 0.08);
      border: 1px solid var(--button-main);
      border-radius: var(--input-border-radius);
      box-sizing: border-box;
      line-height: 1;

      &:first-child {
        margin-top: 16px;
      }

      &__icon {
        flex: 0 0 auto;
        margin: 0 16px 0 0;
        font-size: 24px;
        color: var(--button-main);
      }

      &__name {
        flex: 1 1 100%;
        margin: 0 8px 0 0;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--input-field-color);
      }

      &__delete {
        @include m.flex(center, center);
        flex: 0 0 48px;
        width: 48px;
        height: 100%;
        box-sizing: border-box;
        cursor: pointer;

        &__icon {
          color: var(--state-error);
          font-size: 20px;
          pointer-events: none;
        }
      }
    }
  }

  input[type='file'] {
    @include m.position(absolute, 0, 0, 0, 0);
    display: initial;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

/* Recaptcha badge */
.grecaptcha-badge {
  display: none;
  visibility: hidden;
}

.recaptcha-notice {
  @include m.flex(start, center);
  grid-column: 1 / -1;
  width: 100%;
  margin: 32px 0 0;
  padding: 8px 12px;
  border-radius: var(--input-border-radius);
  font-size: 14px;
  background: rgba(var(--state-success-rgb), 0.10);
  box-sizing: border-box;

  &__icon {
    font-size: 18px;
    margin: 0 12px 0 0;
    color: var(--state-success);

    @include m.bp(sm) {
      margin: 0 12px 0 0;
    }
  }

  p, a {
    margin-top: unset;
    color: var(--mono-7);
    font-size: 14px;

    &:visited {
      color: var(--mono-7);
    }
  }
}

body[data-dark-mode] {
  .recaptcha-notice {
    background: rgba(var(--mono-1-rgb), 0.08);

    p, a {
      color: var(--mono-4);

      &:visited {
        color: var(--mono-4);
      }
    }
  }
}