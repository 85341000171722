@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.header {
  @include m.grid();
  position: relative;
  padding: 56px 16px 96px;
  width: 100%;
  background: var(--header-background);
  border-bottom: var(--main-element-border);

  @include m.bp(sm) {
    padding: 80px 40px 120px;
  }

  @include m.bp(xl) {
    padding: 160px 64px 200px;
  }

  &__copy {
    grid-column: 1 / -1;
    position: relative;
    align-self: center;
    z-index: 10;

    @include m.bp(sm) {
      padding: unset;
    }

    @include m.bp(xl) {
      grid-column: 1 / span 8;
    }

    &__logo {
      border-radius: unset;

      .image {
        width: auto;
        height: auto;
        max-width: 100%;

        /*
         * Logo sizing
         */
        @each $bp, $sizes in v.$header-logo-sizes {

          @include m.bp($bp) {
            @each $size, $height in $sizes {
              &[data-logo-height=#{$size}] {
                max-height: $height;
              }
            }
          }
        }
      }
    }

    &__copy {
      margin-top: unset;
    }

    &__actions {
      @include m.flex(start, center);
      gap: 8px;
      margin-top: 24px;

      @include m.bp(sm) {
        margin-top: 40px;
      }
    }
  }

  &__background {
    @include m.position(absolute, 0, 0, 0, 0);
    z-index: 0;

    &:after {
      @include m.position(absolute, 0, 0, 0, 0);
      content: '';
      background: rgba(255, 255, 255, 0.56);

      @include m.bp(lg) {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.56) 80%, rgba(255, 255, 255, 0.00) 100%);
      }

      @include m.bp(xl) {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.56) 60%, rgba(255, 255, 255, 0.00) 100%);
      }
    }

    &__picture, .video__media {
      height: 100%;
      border-radius: 0;
    }
  }

  &[data-dark-mode] &__background:after {
    background: rgba(0, 0, 0, 0.56);

    @include m.bp(lg) {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 80%, rgba(0, 0, 0, 0.00) 100%);
    }

    @include m.bp(xl) {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 60%, rgba(0, 0, 0, 0.00) 100%);
    }
  }
}

.header {
  &--showcase {
    padding: 16px 16px 40px;

    @include m.bp(sm) {
      padding: 40px 24px;
    }

    @include m.bp(md) {
      padding: 40px;
    }
  }

  &--showcase & {
    &__picture {
      grid-column: 1 / -1;
      height: 240px;
      align-self: center;

      @include m.bp(sm) {
        grid-column: span 5;
        height: 100%;
        max-height: 480px;
      }

      @include m.bp(lg) {
        grid-column: span 8;
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }

    &__copy {
      @include m.bp(sm) {
        grid-column: span 6 / -1;
        padding: 24px 0;
        max-width: 640px;
      }

      @include m.bp(lg) {
        max-width: 720px;
        grid-column: span 9 / -1;
      }

      .heading__title {
        padding-top: 8px;
      }

      &__list {
        margin: 16px 0 0;
        color: inherit;

        &__item {
          @include m.flex(start, center);
          gap: 8px;
          margin: 4px 0 0;
        }
      }
    }
  }
}