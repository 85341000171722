@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-buttons {
  @include m.flex(start, center);
  gap: 8px;

  @include m.bp(null, sm) {
    .button .label {
      display: none;
    }
  }
}