@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

/*
 * Labels
 */
label, legend {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  font-weight: v.$font-semi-bold;
}

.label {

  &--required:after {
    content: '•';
    display: inline-block;
    color: var(--state-error);
    transform: translate(1px, -4px);
  }

  &--checkbox, &--radio {
    font-weight: v.$font-regular;
    user-select: none;
  }

  &__description {
    display: block;
    font-weight: v.$font-regular;
  }

  &__note {
    display: block;
    margin: 0 0 -1px;
    color: var(--mono-6);
    font-weight: v.$font-regular;
    font-size: 0.84em;
    line-height: 1.28em;
  }
}


/*
 * Input Fields
 */

%input {
  width: 100%;
  min-width: v.$button-size;
  height: v.$button-size;
  margin: 0.24rem 0 0;
  padding: 8px 12px;
  background: var(--input-field-background);
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  text-decoration: none;
  color: var(--input-field-color);
  font-size: min(1rem, 16px);
  transition: v.$input-transition;
  box-sizing: border-box;

  // TODO: Unify button sizing
  @include m.bp(sm) {
    min-width: 48px;
    height: 48px;
  }
}

input, textarea, select {
  @extend %input;

  &:hover, &:focus, &:focus-within {
    border-color: var(--brand-main-active);
  }
}

select {
  min-width: 0;
}

label ~ .select2 {
  margin: 8px 0 0;
}

input[type='checkbox'], input[type='radio'] {
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  min-width: unset;
  min-height: unset;
}

input[type="file"] {
  display: none;
}

textarea {
  resize: none;
  min-height: 160px;
}

/*
 * Links
 */

.link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  gap: 4px;

  &__label {
    text-decoration: underline;
    font-weight: v.$font-semi-bold;
  }

  .icon {
    color: inherit;
    font-size: 1.12em;

    &--after {
      order: 2;
    }
  }
}

/*
 * Tags
 */

.tag {
  margin: 0 4px;
  font-weight: v.$font-semi-bold;
  color: var(--link-color);
  text-decoration: none;

  &--solid {
    padding: 6px 8px;
    background: var(--mono-2);
    border-radius: var(--input-border-radius);
    color: var(--text-color-dark);
  }
}