.phpdebugbar {
  pointer-events: initial !important;

  * {
    font-size: 14px !important;
  }

  .phpdebugbar-drag-capture {
    display: none !important;
  }
}

.phpdebugbar-panel:not(.phpdebugbar-active) {
  display: none;
}

.phpdebugbar-panel.phpdebugbar-active {
  display: block !important;
}