@use '../../../../../../resources/scss/mixins' as m;

.footer-widget-social-media-buttons {
  @include m.flex(start, center);
  gap: 8px;

  .button {
    border-color: rgb(var(--mono-0-rgb), 0.48);
    padding: 0;

    .icon {
      font-size: 1.5em !important;
    }
  }
}