@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-contact-button {
  border: unset;
  border-radius: unset;
  color: var(--nav-top-bar-accent-color);
  background: var(--nav-top-bar-accent);

  @include m.interaction() {
    background: var(--nav-top-bar-accent-color);
    color: var(--nav-top-bar-accent);
  }

  &[data-style="whatsapp"] {
    background: #25d366;
    color: #075e54;

    @include m.interaction() {
      background: #075e54;
      color: #25d366;
    }
  }
}