@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;
@use 'fonts';

.hint {
  position: relative;
  grid-column: 1 / -1;
  margin: 8px 0;
  border: 1px solid var(--card-border);
  background: rgba(var(--cards-background-rgb), 0.012);
  border-radius: var(--shape-border-radius);

  &:before {
    @include m.position(absolute, 0, 0, 0, 0);
    content: '';
    z-index: 0;
  }

  @include m.generateThemeColors('state') {
    &--#{m.$gtc-color} {
      border: 1px solid var(--state-#{m.$gtc-color});

      &:before {
        background: rgba(var(--state-#{m.$gtc-color}-rgb), 0.012);
      }

      .icon {
        color: var(--state-#{m.$gtc-color});
      }
    }
  }

  &__header {
    @include m.flex(between, center);
    position: relative;
    padding: 8px;

    @include m.bp(sm) {
      padding: 8px 12px;
    }

    &__title {
      @extend h5;
      @include m.flex(start, center);
      gap: 8px;
      padding: unset;
    }

    &__action {

    }
  }

  &__description {
    @include m.flex(start, start);
    gap: 8px;
    margin: 0;
    position: relative;
    padding: 8px;

    @include m.bp(sm) {
      gap: 8px;
      padding: 8px 12px;
    }

    .icon {
      margin: 4px 0 0;
      font-size: 24px;
    }
  }
}