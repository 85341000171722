@charset "UTF-8";
/*
 * Variables
 */
/* Grid sizing */
/* Page and section sizing */
/* Font weights */
/* z-indexes */
/* Select2 dropdown */
/* Button sizing */
/* Image alignments and ratios */
/* Section padding */
/*
 *  Overlays
 */
/* Logo sizes for navigation and footer */
/* Theme colors */
/* Grid columns and gaps */
:root {
  --grid-gap-xs: 16px;
  --grid-gap-sm: 24px;
  --grid-col-xs: 6;
  --grid-col-sm: 12;
  --grid-col-xl: 18;
  --max-grid-width: calc(var(--grid-width) * var(--grid-col-xl) + var(--grid-gap-sm) * var(--grid-col-xl));
}

/*
 * Transitions
 */
/* Element shadows */
/*
 *  # Experimental SCSS Styling Mixins V1.0
 *  A collection of non standard mixins.
 *
 *  Warning!  Some of these mixins use non-standard properties to solve specific cases,
 *            while other mixins impact user accessibility. These mixins should be used carefully
 *            or not at all when more stable options are available.
 */
/*
 * # Hide Scrollbars
 * Hide scrollbars inside the current element.
 *
 * Warning! This mixin impacts accessibility by hiding scrollbars inside an scrolling element.
 *          Make sure to use a replacement element to indicate the scroll position.
 */
/*
 * # Browser Exceptions
 * Create styling for specific browsers.
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *          Only use this if nothing else can solve your case.
 *
 * $browser - String - set the browser name write styling for. | Options: firefox, chrome, safari, internet-explorer
 */
/*
 * # Detects the users inputMethod
 * Only activate your styling when a certain inputMethod is used
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *
 */
/*
 * # Set styling when interacting with an element
 *
 * $mode (optional)
 *        - active -  uses the :active selector instead of :focus and :hover.
 *        - focus  -  only sets :focus and :focus-within.
 *
 * Warning! This feature is still experimental and thus subject to possible changes in the future.
 *
 */
/*
 *  # SCSS Layout Mixins V1.0
 *  A set of mixins to rapidly build layouts.
 *
 */
/*
 * # Breakpoints
 * Add styling for specific screen sizes.
 *
 * $minSize - Set the min-width property | options: xs, sm, md, lg, xl, xxl
 * $maxSize - Set the max-width property | options: xs, sm, md, lg, xl, xxl
 *
 */
/*
 * # Flex layout
 * Create flex layouts inside a container element.
 *
 *
 */
/*
 * # Grid containers
 * Create grid views inside a container element.
 *
 * @param Integer $col-xs - Set the amount of columns for xs and default screen sizes
 * @param Integer $col-sm - Set the amount of columns for sm screen sizes
 * @param Integer $col-lg - Set the amount of columns for lg screen sizes
 * @param Integer $col-xl - Set the amount of columns for xl and xxl screen sizes
 * @param {px, %} $max-col-width - Overwrite the default max width on large screens
 */
/*
 * # Element positioning
 * Shortcuts for absolute, fixed and sticky positioning.
 *
 * $position  - String - Either 'fixed', 'absolute' or 'sticky' to set the css position property.
 * $top       - String - Set the value for the top property.    | Default: null
 * $right     - String - Set the value for the right property.  | Default: null
 * $bottom    - String - Set the value for the bottom property. | Default: null
 * $left      - String - Set the value for the left property.   | Default: null
 */
/*
 * # Section Padding Sizes
 * Renders margin or padding sizes for a container.
 *
 * $mode - either margin or padding.
 */
/*
 *  # SCSS Styling Mixins V1.0
 *  These mixins are made to quickly build SCSS layouts.
 *
 */
/*
 * # Background overlay
 * Adds a dark overlay as :after pseudo selector to backgrounds
 */
/*
 * # Theme color loop
 * Loops over all theme colors to generate theme color variants
 *
 * Available variables in @content:
 * - $type | The theme color type, either 'brand' or 'state'.
 * - $color | The current theme color name.
 */
/*
 * # Card styling
 * All card styling properties in a single mixin.
 */
/*
 * CSS Reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1;
}

/*
 * HTML and Body styling
 */
:root {
  font-size: calc(var(--text-size) * 14px);
}
@media (min-width: 1600px) {
  :root {
    font-size: calc(var(--text-size) * 16px);
  }
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--text-color-dark);
  background: var(--body-color);
  min-height: 100vh;
  box-sizing: border-box;
  transition: opacity 400ms cubic-bezier(0.64, 0, 0.78, 0), transform 400ms cubic-bezier(0.64, 0, 0.78, 0), visibility 1ms 400ms;
}
body[data-dark-mode] {
  color: var(--text-color-light);
}
body[data-lock-body=true] {
  overflow: hidden;
}
body[data-active-overlay] {
  overflow: hidden;
}

#page-header, #page-content {
  flex: 1 1 100%;
  width: 100%;
}

main, #content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;
  scroll-margin: 24px;
}
@media (min-width: 768px) {
  main, #content {
    scroll-margin: 80px;
  }
}
@media (min-width: 1600px) {
  main, #content {
    scroll-margin: 128px;
  }
}

/*
 * Accessibility and highlighting
 */
::selection {
  background: var(--brand-main);
  color: var(--brand-main-label);
}

:focus {
  outline: 1px solid var(--mono-2);
  outline-offset: 4px;
}

/*
 * Sections
 * - Grid setup
 * - Section and form setup
 */
section, form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: var(--section-padding) 0;
  padding: 0 16px;
  scroll-margin: 16px;
  /* Modifier for full width pages and full width / background blocks */
  /* Modifiers for different margin / padding variants */
}
@media (min-width: 768px) {
  section, form {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  section, form {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1600px) {
  section, form {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 768px) {
  section, form {
    padding: 0 24px;
    scroll-margin: 128px;
  }
}
@media (min-width: 1024px) {
  section, form {
    padding: 0 40px;
  }
}
@media (min-width: 1600px) {
  section, form {
    margin: calc(var(--section-padding) * 1.4) 0;
    padding: 0 64px;
  }
}
body[data-page-width="1fr"] section, section[data-background], section.full-width, body[data-page-width="1fr"] form, form[data-background], form.full-width {
  padding: 0;
}
@media (min-width: 768px) {
  body[data-page-width="1fr"] section, section[data-background], section.full-width, body[data-page-width="1fr"] form, form[data-background], form.full-width {
    padding: 0;
  }
}
@media (min-width: 1600px) {
  body[data-page-width="1fr"] section, section[data-background], section.full-width, body[data-page-width="1fr"] form, form[data-background], form.full-width {
    padding: 0;
  }
}
section[data-background], form[data-background] {
  padding: 0;
  width: calc(100% - 32px);
  margin: var(--section-padding) -16px;
  border-radius: var(--shape-border-radius);
}
@media (min-width: 768px) {
  section[data-background], form[data-background] {
    padding: 0;
    width: calc(100% - 48px);
    margin: var(--section-padding) -24px;
  }
}
@media (min-width: 1024px) {
  section[data-background], form[data-background] {
    padding: 0;
    width: calc(100% - 80px);
    margin: var(--section-padding) -40px;
  }
}
@media (min-width: 1600px) {
  section[data-background], form[data-background] {
    padding: 0;
    width: calc(100% - 128px);
    margin: var(--section-padding) -64px;
  }
}
section.error, form.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-column: 1/-1;
  flex: 1 1 100%;
  width: 100%;
  margin: 128px auto;
}
section.error > .icon, form.error > .icon {
  font-size: 4rem;
  color: var(--brand-main);
  animation: pulse 2.4s cubic-bezier(0.72, 0.08, 0.28, 0.92) infinite;
}
section.error__message, form.error__message {
  text-align: center;
}

@keyframes pulse {
  0% {
    opacity: 0.24;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.24;
  }
}
/*
 * Headings
 */
h1, h2, h3, h4, .footer .widget-title, .footer .heading__title, h5, .hint__header__title, .cookie-banner .heading__title, h6 {
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight);
  line-height: calc(var(--heading-line-height) * 1.28);
  letter-spacing: calc(var(--heading-letter-spacing) * 1px);
  color: var(--heading-color-dark);
  grid-column: 1/-1;
  word-wrap: break-word;
}

h1 {
  font-size: calc(2.2rem * var(--heading-size));
  padding: 2.2rem 0 0.4rem;
}

h2 {
  font-size: calc(2rem * var(--heading-size));
  padding: 1.8rem 0 0.3rem;
}

h3 {
  font-size: calc(1.6rem * var(--heading-size));
  padding: 1.4rem 0 0.2rem;
}

h4, .footer .widget-title, .footer .heading__title {
  font-size: calc(1.4rem * var(--heading-size));
  padding: 1.2rem 0 0.1rem;
}

h5, .hint__header__title, .cookie-banner .heading__title {
  font-size: calc(1.2rem * var(--heading-size));
  padding: 1rem 0 0;
}

h6 {
  font-size: calc(1rem * var(--heading-size));
  padding: 0.8rem 0 0;
}

/*
 * Light text modifiers for dark page backgrounds
 */
body[data-dark-mode] h1, body[data-dark-mode] h2, body[data-dark-mode] h3, body[data-dark-mode] h4, body[data-dark-mode] .footer .widget-title, .footer body[data-dark-mode] .widget-title, body[data-dark-mode] .footer .heading__title, .footer body[data-dark-mode] .heading__title, body[data-dark-mode] h5, body[data-dark-mode] .hint__header__title, body[data-dark-mode] .cookie-banner .heading__title, .cookie-banner body[data-dark-mode] .heading__title, body[data-dark-mode] h6 {
  color: var(--heading-color-light);
}

p, cite, b, strong, i, em, pre, span,
address, del, ins, figure, figcaption, blockquote, q, a,
th, td, ul, ol, li,
label, fieldset, legend, input, textarea, select, button {
  font-family: var(--text-font);
  font-weight: var(--text-font-weight);
  font-size: 1rem;
  line-height: calc(var(--text-line-height) * 1.6);
  letter-spacing: calc(var(--text-letter-spacing) * 1px);
  word-wrap: break-word;
}

p, cite, b, strong, i, em, address, del, ins, blockquote, q, a {
  margin: 0.48rem 0 0;
}

p {
  grid-column: 1/-1;
}

blockquote, q, th, i {
  font-style: italic;
}

blockquote, q, th {
  color: var(--mono-5);
}

a:not(.button, .tag, .menu-item) {
  color: var(--link-color);
  font-weight: 600;
  transition: 0.4s;
}
a:not(.button, .tag, .menu-item):hover, a:not(.button, .tag, .menu-item):focus {
  color: var(--link-color-active);
}
a:not(.button, .tag, .menu-item):visited {
  color: var(--link-color-visited);
}
a:not(.button, .tag, .menu-item):visited:hover, a:not(.button, .tag, .menu-item):visited:focus {
  color: var(--link-color-active);
}

b, strong {
  font-weight: 700;
}

i, em {
  font-style: italic;
}

ul, ol {
  padding-left: 20px;
  margin: 1rem 0 0;
}

sup {
  vertical-align: super;
  font-size: 0.64rem;
}

sub {
  vertical-align: sub;
  font-size: 0.64rem;
}

.note {
  font-size: 0.88rem;
  color: var(--mono-6);
}

/*
 * This file is based on Select2 v4.0.0
 * Source: https://select2.org/
 */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  width: 100%;
  position: relative;
  vertical-align: middle;
  /*
   * This file is based on the Select2 v4.0.0 default theme
   * Source: https://select2.org/
   */
  /*
   * This file is based on the Select2 v4.0.0 default theme
   * Source: https://select2.org/
   */
}
.select2-container:focus, .select2-container:focus-within {
  cursor: pointer;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .select2-container:hover {
    cursor: pointer;
  }
}
.select2-container .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
}
.select2-container .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

/*
 * Variables
 */
/* Grid sizing */
/* Page and section sizing */
/* Font weights */
/* z-indexes */
/* Select2 dropdown */
/* Button sizing */
/* Image alignments and ratios */
/* Section padding */
/*
 *  Overlays
 */
/* Logo sizes for navigation and footer */
/* Theme colors */
/* Grid columns and gaps */
:root {
  --grid-gap-xs: 16px;
  --grid-gap-sm: 24px;
  --grid-col-xs: 6;
  --grid-col-sm: 12;
  --grid-col-xl: 18;
  --max-grid-width: calc(var(--grid-width) * var(--grid-col-xl) + var(--grid-gap-sm) * var(--grid-col-xl));
}

/*
 * Transitions
 */
/* Element shadows */
/*
 * This file is based on Select2 v4.0.0
 * Source: https://select2.org/
 */
.select2-dropdown {
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  background-color: var(--input-field-background);
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
  z-index: 2000;
}

.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 8px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option .icon {
  margin-right: 8px;
}
.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown__below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2 .selection {
  display: block;
}
.select2 .selection .select2-selection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  background: var(--input-field-background);
  color: var(--input-field-color);
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/*
 * This file is based on the Select2 v4.0.0 default theme
 * Source: https://select2.org/
 */
.select2-container--default {
  /*
   * This file is based on the Select2 v4.0.0 default theme
   * Source: https://select2.org/
   */
  /*
   * This file is based on the Select2 v4.0.0 default theme
   * Source: https://select2.org/
   */
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

/*
 * Labels
 */
label, legend {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
}

.label--required:after {
  content: "•";
  display: inline-block;
  color: var(--state-error);
  transform: translate(1px, -4px);
}
.label--checkbox, .label--radio {
  font-weight: 400;
  user-select: none;
}
.label__description {
  display: block;
  font-weight: 400;
}
.label__note {
  display: block;
  margin: 0 0 -1px;
  color: var(--mono-6);
  font-weight: 400;
  font-size: 0.84em;
  line-height: 1.28em;
}

/*
 * Input Fields
 */
.prefix, .suffix, input, textarea, select {
  width: 100%;
  min-width: 44px;
  height: 44px;
  margin: 0.24rem 0 0;
  padding: 8px 12px;
  background: var(--input-field-background);
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  text-decoration: none;
  color: var(--input-field-color);
  font-size: min(1rem, 16px);
  transition: background 0.24s, color 0.24s, border-color 0.24s;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .prefix, .suffix, input, textarea, select {
    min-width: 48px;
    height: 48px;
  }
}

input:hover, input:focus, input:focus-within, textarea:hover, textarea:focus, textarea:focus-within, select:hover, select:focus, select:focus-within {
  border-color: var(--brand-main-active);
}

select {
  min-width: 0;
}

label ~ .select2 {
  margin: 8px 0 0;
}

input[type=checkbox], input[type=radio] {
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  min-width: unset;
  min-height: unset;
}

input[type=file] {
  display: none;
}

textarea {
  resize: none;
  min-height: 160px;
}

/*
 * Links
 */
.link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  gap: 4px;
}
.link__label {
  text-decoration: underline;
  font-weight: 600;
}
.link .icon {
  color: inherit;
  font-size: 1.12em;
}
.link .icon--after {
  order: 2;
}

/*
 * Tags
 */
.tag {
  margin: 0 4px;
  font-weight: 600;
  color: var(--link-color);
  text-decoration: none;
}
.tag--solid {
  padding: 6px 8px;
  background: var(--mono-2);
  border-radius: var(--input-border-radius);
  color: var(--text-color-dark);
}

/*
 * Buttons
 */
.section__header__quick-action .button, .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex: 0 0 auto;
  position: relative;
  min-height: 44px;
  min-width: 44px;
  margin: 0;
  padding: 0 12px;
  gap: 8px;
  color: var(--button-main-label);
  border: 1px solid transparent;
  border-radius: var(--input-border-radius);
  font-family: var(--text-font), sans-serif;
  font-weight: 500;
  letter-spacing: 0.98px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  transition: background 0.24s, color 0.24s, border-color 0.24s;
  /* Prevent accidental text selection */
}
.section__header__quick-action .button *, .button * {
  pointer-events: none;
}
.button:active {
  user-select: none;
}
.button:has(.loading-indicator[style*="display: block"]) {
  pointer-events: none;
  color: var(--mono-4);
  background: var(--state-disabled);
  border: 1px solid var(--state-disabled);
}
.button .loading-indicator.icon {
  display: none;
  font-size: 1.4em;
}
.button .loading-indicator.icon[style*="display: block"] ~ * {
  display: none;
}
.button .label {
  color: inherit;
  font-weight: inherit;
}
.button .icon {
  flex: 0;
  color: inherit;
  font-size: 1.12em;
  opacity: 0.8;
}
.button .icon:first-child:last-child {
  font-size: 1em;
}
.button .icon--after {
  order: 2;
}
.button .flag {
  width: 1.12em;
}

.button {
  /* Outline button */
  /* Text buttons */
  /* Outline button */
  /* Text buttons */
  /* Outline button */
  /* Text buttons */
  /* Outline button */
  /* Text buttons */
  /* Outline button */
  /* Text buttons */
  /* Outline button */
  /* Text buttons */
  /*
   * Modifiers
   */
  /* make the button full width */
  /* hide labels and icons on certain breakpoints */
}
.button--main, .section__header__quick-action .button {
  color: var(--button-main-label);
  background: var(--button-main);
  border-color: var(--button-main);
}
.button--main:focus, .section__header__quick-action .button:focus, .button--main:focus-within, .section__header__quick-action .button:focus-within {
  background: var(--button-main-active);
  border-color: var(--button-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--main:hover, .section__header__quick-action .button:hover {
    background: var(--button-main-active);
    border-color: var(--button-main-active);
  }
}
.button--outline.button--main, .section__header__quick-action .button {
  border-color: var(--button-main);
  color: var(--button-main-outline-label);
  background: unset;
}
.button--outline.button--main:focus, .section__header__quick-action .button:focus, .button--outline.button--main:focus-within, .section__header__quick-action .button:focus-within {
  background: rgba(var(--button-main-rgb), 0.08);
  color: var(--button-main);
  border-color: var(--button-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--main:hover, .section__header__quick-action .button:hover {
    background: rgba(var(--button-main-rgb), 0.08);
    color: var(--button-main);
    border-color: var(--button-main-active);
  }
}
.button--outline.button--main:active, .section__header__quick-action .button:active {
  background: rgba(var(--button-main-rgb), 0.4);
}
.button--text.button--main, .section__header__quick-action .button--text.button {
  color: var(--button-main-outline-label);
}
.button--text.button--main:focus, .section__header__quick-action .button--text.button:focus, .button--text.button--main:focus-within, .section__header__quick-action .button--text.button:focus-within {
  color: var(--button-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--main:hover, .section__header__quick-action .button--text.button:hover {
    color: var(--button-main-active);
  }
}
.button--accent {
  color: var(--button-accent-label);
  background: var(--button-accent);
  border-color: var(--button-accent);
}
.button--accent:focus, .button--accent:focus-within {
  background: var(--button-accent-active);
  border-color: var(--button-accent-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--accent:hover {
    background: var(--button-accent-active);
    border-color: var(--button-accent-active);
  }
}
.button--outline.button--accent, .section__header__quick-action .button--accent.button {
  border-color: var(--button-accent);
  color: var(--button-accent-outline-label);
  background: unset;
}
.button--outline.button--accent:focus, .section__header__quick-action .button--accent.button:focus, .button--outline.button--accent:focus-within, .section__header__quick-action .button--accent.button:focus-within {
  background: rgba(var(--button-accent-rgb), 0.08);
  color: var(--button-accent);
  border-color: var(--button-accent-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--accent:hover, .section__header__quick-action .button--accent.button:hover {
    background: rgba(var(--button-accent-rgb), 0.08);
    color: var(--button-accent);
    border-color: var(--button-accent-active);
  }
}
.button--outline.button--accent:active, .section__header__quick-action .button--accent.button:active {
  background: rgba(var(--button-accent-rgb), 0.4);
}
.button--text.button--accent {
  color: var(--button-accent-outline-label);
}
.button--text.button--accent:focus, .button--text.button--accent:focus-within {
  color: var(--button-accent-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--accent:hover {
    color: var(--button-accent-active);
  }
}
.button--info {
  color: var(--state-info-label);
  background: var(--state-info);
  border-color: var(--state-info);
}
.button--info:focus, .button--info:focus-within {
  background: var(--state-info-active);
  border-color: var(--state-info-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--info:hover {
    background: var(--state-info-active);
    border-color: var(--state-info-active);
  }
}
.button--outline.button--info, .section__header__quick-action .button--info.button {
  border-color: var(--state-info);
  color: var(--state-info-outline-label);
  background: unset;
}
.button--outline.button--info:focus, .section__header__quick-action .button--info.button:focus, .button--outline.button--info:focus-within, .section__header__quick-action .button--info.button:focus-within {
  background: rgba(var(--state-info-rgb), 0.08);
  color: var(--state-info);
  border-color: var(--state-info-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--info:hover, .section__header__quick-action .button--info.button:hover {
    background: rgba(var(--state-info-rgb), 0.08);
    color: var(--state-info);
    border-color: var(--state-info-active);
  }
}
.button--outline.button--info:active, .section__header__quick-action .button--info.button:active {
  background: rgba(var(--state-info-rgb), 0.4);
}
.button--text.button--info {
  color: var(--state-info-outline-label);
}
.button--text.button--info:focus, .button--text.button--info:focus-within {
  color: var(--state-info-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--info:hover {
    color: var(--state-info-active);
  }
}
.button--success {
  color: var(--state-success-label);
  background: var(--state-success);
  border-color: var(--state-success);
}
.button--success:focus, .button--success:focus-within {
  background: var(--state-success-active);
  border-color: var(--state-success-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--success:hover {
    background: var(--state-success-active);
    border-color: var(--state-success-active);
  }
}
.button--outline.button--success, .section__header__quick-action .button--success.button {
  border-color: var(--state-success);
  color: var(--state-success-outline-label);
  background: unset;
}
.button--outline.button--success:focus, .section__header__quick-action .button--success.button:focus, .button--outline.button--success:focus-within, .section__header__quick-action .button--success.button:focus-within {
  background: rgba(var(--state-success-rgb), 0.08);
  color: var(--state-success);
  border-color: var(--state-success-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--success:hover, .section__header__quick-action .button--success.button:hover {
    background: rgba(var(--state-success-rgb), 0.08);
    color: var(--state-success);
    border-color: var(--state-success-active);
  }
}
.button--outline.button--success:active, .section__header__quick-action .button--success.button:active {
  background: rgba(var(--state-success-rgb), 0.4);
}
.button--text.button--success {
  color: var(--state-success-outline-label);
}
.button--text.button--success:focus, .button--text.button--success:focus-within {
  color: var(--state-success-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--success:hover {
    color: var(--state-success-active);
  }
}
.button--warning {
  color: var(--state-warning-label);
  background: var(--state-warning);
  border-color: var(--state-warning);
}
.button--warning:focus, .button--warning:focus-within {
  background: var(--state-warning-active);
  border-color: var(--state-warning-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--warning:hover {
    background: var(--state-warning-active);
    border-color: var(--state-warning-active);
  }
}
.button--outline.button--warning, .section__header__quick-action .button--warning.button {
  border-color: var(--state-warning);
  color: var(--state-warning-outline-label);
  background: unset;
}
.button--outline.button--warning:focus, .section__header__quick-action .button--warning.button:focus, .button--outline.button--warning:focus-within, .section__header__quick-action .button--warning.button:focus-within {
  background: rgba(var(--state-warning-rgb), 0.08);
  color: var(--state-warning);
  border-color: var(--state-warning-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--warning:hover, .section__header__quick-action .button--warning.button:hover {
    background: rgba(var(--state-warning-rgb), 0.08);
    color: var(--state-warning);
    border-color: var(--state-warning-active);
  }
}
.button--outline.button--warning:active, .section__header__quick-action .button--warning.button:active {
  background: rgba(var(--state-warning-rgb), 0.4);
}
.button--text.button--warning {
  color: var(--state-warning-outline-label);
}
.button--text.button--warning:focus, .button--text.button--warning:focus-within {
  color: var(--state-warning-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--warning:hover {
    color: var(--state-warning-active);
  }
}
.button--error {
  color: var(--state-error-label);
  background: var(--state-error);
  border-color: var(--state-error);
}
.button--error:focus, .button--error:focus-within {
  background: var(--state-error-active);
  border-color: var(--state-error-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--error:hover {
    background: var(--state-error-active);
    border-color: var(--state-error-active);
  }
}
.button--outline.button--error, .section__header__quick-action .button--error.button {
  border-color: var(--state-error);
  color: var(--state-error-outline-label);
  background: unset;
}
.button--outline.button--error:focus, .section__header__quick-action .button--error.button:focus, .button--outline.button--error:focus-within, .section__header__quick-action .button--error.button:focus-within {
  background: rgba(var(--state-error-rgb), 0.08);
  color: var(--state-error);
  border-color: var(--state-error-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--outline.button--error:hover, .section__header__quick-action .button--error.button:hover {
    background: rgba(var(--state-error-rgb), 0.08);
    color: var(--state-error);
    border-color: var(--state-error-active);
  }
}
.button--outline.button--error:active, .section__header__quick-action .button--error.button:active {
  background: rgba(var(--state-error-rgb), 0.4);
}
.button--text.button--error {
  color: var(--state-error-outline-label);
}
.button--text.button--error:focus, .button--text.button--error:focus-within {
  color: var(--state-error-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--text.button--error:hover {
    color: var(--state-error-active);
  }
}
.button--link {
  padding: unset;
  color: var(--link-color);
  transition: background 0.24s, color 0.24s, border-color 0.24s;
  min-height: unset;
}
.button--link:focus, .button--link:focus-within {
  color: var(--link-color-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .button--link:hover {
    color: var(--link-color-active);
  }
}
.button--disabled, .button--disabled:hover, .button--disabled:focus, .button--disabled:focus-within {
  background: var(--mono-4);
  border-color: var(--mono-4);
  cursor: not-allowed;
}
.button--disabled .label, .button--disabled .icon, .button--disabled:hover .label, .button--disabled:hover .icon, .button--disabled:focus .label, .button--disabled:focus .icon, .button--disabled:focus-within .label, .button--disabled:focus-within .icon {
  color: var(--state-disabled);
  user-select: none;
}
.button--text {
  background: unset !important;
  border: unset;
}
.button--xs {
  min-height: 32px;
  min-width: 32px;
}
.button--full-width {
  width: 100%;
  grid-column: 1/-1;
}
@media (max-width: 767px) {
  .button--hide-label-xs .label {
    display: none;
  }
  .button--hide-icon-xs .icon {
    display: none;
  }
}
@media (min-width: 768px) {
  .button--hide-label-sm .label {
    display: none;
  }
  .button--hide-icon-sm .icon {
    display: none;
  }
}

/* Temporary fix for button logic */
[data-dark-mode] .button--outline .icon, [data-dark-mode] .section__header__quick-action .button .icon, .section__header__quick-action [data-dark-mode] .button .icon, [data-dark-mode] .button--outline .label, [data-dark-mode] .section__header__quick-action .button .label, .section__header__quick-action [data-dark-mode] .button .label {
  color: var(--text-color-light);
}

/* Prefixes and suffixes */
.prefix, .suffix {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: unset;
  line-height: unset;
  color: var(--mono-7);
}

.prefix {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.prefix:not(.button) {
  border-right: unset;
}

.suffix {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
.suffix:not(.button) {
  border-left: unset;
}

.input--prefix, .input--suffix {
  flex: 1;
  width: 100%;
}
.input--prefix {
  border-left: unset;
  padding-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
.input--suffix {
  border-right: unset;
  padding-right: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.inline-login__container, .section-button, .section {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 0;
  margin-top: 16px;
  background: var(--section-background);
  border: 1px solid var(--section-border);
  border-radius: var(--shape-border-radius);
  overflow: hidden;
  grid-column: 1/-1;
  max-height: stretch;
}
.inline-login__container .section, .section-button .section, .section .section {
  max-height: unset;
}
@media (min-width: 768px) {
  .inline-login__container, .section-button, .section {
    margin-top: 24px;
  }
}

@media (min-width: 768px) {
  .section:not([data-grid-size]) {
    min-width: 480px;
  }
}
.section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px;
  grid-column: 1/-1;
  padding-left: 10px;
  border-bottom: 1px solid var(--section-border);
  min-height: 44px;
}
@media (min-width: 768px) {
  .section__header {
    padding-left: 16px;
    min-height: 52px;
  }
}
.section__header:last-child {
  border-bottom: unset;
}
.section__header__heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  flex: 1 1 auto;
  overflow: hidden;
  max-width: unset;
}
.section__header__heading__icon {
  color: var(--brand-main);
  font-size: 1.28em;
}
.section__header__heading__title {
  font-size: 1rem;
  padding: 0;
  margin: 8px 0;
  overflow: hidden;
}
.section__header__quick-action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-left: 1px solid var(--section-border);
}
.section__header__quick-action .button {
  flex: 0 0 auto;
  border: unset;
  border-radius: unset;
  align-self: stretch;
}
.section__header__quick-action .button:focus, .section__header__quick-action .button:focus-within {
  border-radius: unset;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .section__header__quick-action .button:hover {
    border-radius: unset;
  }
}
.section--copy .section__content {
  padding: 0 12px 16px;
}
@media (min-width: 768px) {
  .section--copy .section__content {
    padding: 0 16px 24px;
  }
}
@media (min-width: 1280px) {
  .section--copy .section__content {
    padding: 0 24px 24px;
  }
}
.section__content {
  overflow-y: auto;
  max-height: 100%;
}
.section__content__heading {
  padding: 0 12px 8px;
}
@media (min-width: 768px) {
  .section__content__heading {
    padding: 0 16px 8px;
  }
}
.section__content .form {
  padding: 4px 12px 16px;
}
@media (min-width: 768px) {
  .section__content .form {
    padding: 4px 16px 24px;
  }
}
.section__content > .form-group {
  margin: 0;
  padding: 0 12px 16px;
}
@media (min-width: 768px) {
  .section__content > .form-group {
    padding: 0 16px 16px;
  }
}
.section__content > .list, .section__content > .grid-list {
  padding: 16px 12px 4px;
}
@media (min-width: 768px) {
  .section__content > .list, .section__content > .grid-list {
    padding: 24px;
  }
}
.section__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  border-top: 1px solid var(--section-border);
}
@media (max-width: 767px) {
  .section__actions .button {
    flex: 1 1 auto;
  }
}
@media (min-width: 768px) {
  .section__actions {
    gap: 16px;
  }
  .section__actions > *:last-child {
    margin-left: auto;
  }
}
.section__actions .footnote {
  margin: auto;
  padding: unset;
  color: var(--mono-7);
  font-size: 0.88rem;
  text-align: center;
}

.section-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  gap: 16px;
  padding: 0;
}
@media (min-width: 768px) {
  .section-wrapper {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .section-wrapper {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1600px) {
  .section-wrapper {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 768px) {
  .section-wrapper {
    gap: 24px;
  }
}

.section-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  height: 48px;
  padding: 0 10px;
  cursor: pointer;
  transition: background 0.24s, color 0.24s, border-color 0.24s;
}
@media (min-width: 768px) {
  .section-button {
    gap: 12px;
    height: 56px;
    padding: 0 16px;
  }
}
.section-button:focus, .section-button:focus-within {
  background: rgba(var(--brand-accent-rgb), 0.08);
  border: 1px solid var(--brand-accent);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .section-button:hover {
    background: rgba(var(--brand-accent-rgb), 0.08);
    border: 1px solid var(--brand-accent);
  }
}
.section-button__icon {
  color: var(--brand-accent);
  font-size: 1.28rem;
}
.section-button__label {
  color: var(--text-color-dark);
  font-weight: 600;
}

.form {
  /* Submit button */
}
.form--hidden {
  display: none;
  visibility: hidden;
}
.form label, .form input, .form textarea, .form select, .form option {
  accent-color: var(--brand-main);
}
.form textarea {
  height: 100%;
  box-sizing: border-box;
}
.form .button {
  grid-column: 1/-1;
  margin: 1rem 0 0;
}

.heading {
  margin-top: 16px;
}

.form-group {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-auto-flow: dense;
  grid-column: 1/-1;
  align-items: start;
}
@media (min-width: 768px) {
  .form-group {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .form-group {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1600px) {
  .form-group {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
.form-group--submit {
  align-items: center;
}
@media (min-width: 768px) {
  .form-group .button {
    grid-column: span 6/-1;
  }
}
@media (min-width: 1600px) {
  .form-group .button {
    grid-column: span 9/-1;
  }
}

.form-comment {
  grid-column: 1/-1;
  margin: -16px 0 16px;
}
@media (min-width: 768px) {
  .form-comment {
    grid-column: 1/span 6;
    margin: unset;
  }
}
@media (min-width: 1600px) {
  .form-comment {
    grid-column: 1/span 9;
  }
}

.input-group {
  grid-column: 1/-1;
  flex: 1 1 auto;
  margin: 0.8rem 0 0;
  /* Alignment */
  /* Textarea */
  /* alignment for checkboxes and radio buttons */
  /* For multiple field parts like prefixes and suffixes */
  /* Container for fieldset groups */
  /* Address form group */
  /* Range input fiter */
  /* Disabled modifier */
  /* Input styling for inputs with side buttons */
}
.input-group--stretch {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.input-group--borderless .input-group__container {
  background: unset;
  border: unset;
  padding: 0;
}
.input-group--file-upload {
  height: calc(100% - 24px);
}
@media (min-width: 768px) {
  .input-group--scroll-list .input-group__container {
    max-height: 496px;
    overflow-y: scroll;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .input-group--left {
    grid-column: 1/span 6;
  }
  .input-group--right {
    grid-column: span 6/-1;
  }
}
@media (min-width: 1280px) {
  .input-group--left {
    grid-column: 1/span 9;
  }
  .input-group--right {
    grid-column: span 9/-1;
  }
}
.input-group--row-2 {
  grid-row: span 2;
}
.input-group--row-3 {
  grid-row: span 3;
}
.input-group--row-4 {
  grid-row: span 4;
}
.input-group--row-5 {
  grid-row: span 5;
}
.input-group--row-6 {
  grid-row: span 6;
}
.input-group--row-7 {
  grid-row: span 7;
}
.input-group--row-8 {
  grid-row: span 8;
}
.input-group--row-9 {
  grid-row: span 9;
}
.input-group--resize-both .textarea {
  resize: both;
}
.input-group--resize-vertical .textarea {
  resize: vertical;
}
.input-group--resize-horizontal .textarea {
  resize: horizontal;
}
.input-group--inline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  gap: 8px;
  margin: unset;
  padding: 8px 0;
}
.input-group--inline label {
  grid-area: label;
}
.input-group--inline input {
  grid-area: input;
}
.input-group--inline .label__comment {
  grid-area: comment;
}
.input-group__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.input-group__container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  margin: 8px 0 0;
  padding: 8px 12px;
  gap: 8px;
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  background: var(--input-field-background);
}
@media (min-width: 768px) {
  .input-group__container {
    height: calc(100% - 8px);
    box-sizing: border-box;
  }
}
@media (min-width: 1280px) {
  .input-group__container {
    gap: 16px;
  }
}
@media (min-width: 1600px) {
  .input-group__container {
    padding: 18px 16px;
  }
}
.input-group__container .input-group {
  margin: unset;
}
.input-group--address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0 8px;
  margin: 0;
}
@media (min-width: 768px) {
  .input-group--address {
    gap: 0 16px;
  }
}
@media (min-width: 1600px) {
  .input-group--address {
    gap: 0 24px;
  }
}
.input-group--address .input-group__street, .input-group--address .input-group__postal-code, .input-group--address .input-group__city {
  flex: 1 0 100%;
  margin: 16px 0 0;
}
@media (min-width: 768px) {
  .input-group--address .input-group__street, .input-group--address .input-group__postal-code, .input-group--address .input-group__city {
    flex: 0 0 calc(50% - 8px);
    margin: 24px 0 0;
  }
}
@media (min-width: 1600px) {
  .input-group--address .input-group__street, .input-group--address .input-group__postal-code, .input-group--address .input-group__city {
    flex: 0 0 calc(50% - 12px);
  }
}
.input-group--address .input-group__house-number, .input-group--address .input-group__house-number-addition {
  flex: 1 0 100%;
  margin: 16px 0 0;
}
@media (min-width: 768px) {
  .input-group--address .input-group__house-number, .input-group--address .input-group__house-number-addition {
    flex: 0 0 calc(25% - 12px);
    margin: 24px 0 0;
  }
}
@media (min-width: 1600px) {
  .input-group--address .input-group__house-number, .input-group--address .input-group__house-number-addition {
    flex: 0 0 calc(25% - 18px);
  }
}
.input-group--address .input-group__country {
  flex: 1 0 100%;
}
.input-group--range-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
@media (min-width: 768px) {
  .input-group--range-filter {
    gap: 16px;
  }
}
.input-group--disabled input, .input-group--disabled textarea {
  pointer-events: none;
  background: var(--mono-4);
}
.input-group--buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.input-group--buttons .input-group__field {
  flex: 0 0 44px;
}
.input-group--buttons .input-group__field input {
  text-align: center;
  -moz-appearance: textfield;
}
.input-group--buttons .input-group__field input::-webkit-outer-spin-button, .input-group--buttons .input-group__field input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

/* File upload field */
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin: 8px 0 0;
  padding: 16px;
  border: 1px dashed var(--input-field-border);
  background: var(--input-field-background);
  text-align: center;
  transition: background 0.24s, color 0.24s, height 0.24s;
}
@media (min-width: 768px) {
  .file-upload {
    height: calc(100% - 88px);
    padding: 24px;
  }
}
.file-upload[data-state=empty] .file-upload__step--drop, .file-upload[data-state=empty] .file-upload__step--done {
  display: none;
}
.file-upload[data-state=hover] {
  background: rgba(var(--button-main-rgb), 0.12);
}
.file-upload[data-state=hover] .file-upload__step--empty, .file-upload[data-state=hover] .file-upload__step--done {
  display: none;
}
.file-upload[data-state=done] .file-upload__step--empty, .file-upload[data-state=done] .file-upload__step--drop {
  display: none;
}
.file-upload__step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.file-upload__step__icon {
  color: var(--button-main);
  font-size: 2.4rem;
}
.file-upload__step__explainer {
  margin: 16px 0 0;
  font-weight: 400;
}
.file-upload__step__explainer b {
  font-weight: 600;
  color: var(--input-field-color);
}
.file-upload__step__explainer--touch {
  display: none;
}
.file-upload__step__explainer__link {
  color: var(--link-color);
  text-decoration: underline;
  font-weight: 600;
}
.file-upload__files {
  width: 100%;
  max-width: 560px;
  z-index: 100;
}
.file-upload__files__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 0 0 16px;
  margin: 0 0 8px;
  background: rgba(var(--button-main-rgb), 0.08);
  border: 1px solid var(--button-main);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
  line-height: 1;
}
.file-upload__files__file:first-child {
  margin-top: 16px;
}
.file-upload__files__file__icon {
  flex: 0 0 auto;
  margin: 0 16px 0 0;
  font-size: 24px;
  color: var(--button-main);
}
.file-upload__files__file__name {
  flex: 1 1 100%;
  margin: 0 8px 0 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--input-field-color);
}
.file-upload__files__file__delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 48px;
  width: 48px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
.file-upload__files__file__delete__icon {
  color: var(--state-error);
  font-size: 20px;
  pointer-events: none;
}
.file-upload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: initial;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

/* Recaptcha badge */
.grecaptcha-badge {
  display: none;
  visibility: hidden;
}

.recaptcha-notice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1/-1;
  width: 100%;
  margin: 32px 0 0;
  padding: 8px 12px;
  border-radius: var(--input-border-radius);
  font-size: 14px;
  background: rgba(var(--state-success-rgb), 0.1);
  box-sizing: border-box;
}
.recaptcha-notice__icon {
  font-size: 18px;
  margin: 0 12px 0 0;
  color: var(--state-success);
}
@media (min-width: 768px) {
  .recaptcha-notice__icon {
    margin: 0 12px 0 0;
  }
}
.recaptcha-notice p, .recaptcha-notice a {
  margin-top: unset;
  color: var(--mono-7);
  font-size: 14px;
}
.recaptcha-notice p:visited, .recaptcha-notice a:visited {
  color: var(--mono-7);
}

body[data-dark-mode] .recaptcha-notice {
  background: rgba(var(--mono-1-rgb), 0.08);
}
body[data-dark-mode] .recaptcha-notice p, body[data-dark-mode] .recaptcha-notice a {
  color: var(--mono-4);
}
body[data-dark-mode] .recaptcha-notice p:visited, body[data-dark-mode] .recaptcha-notice a:visited {
  color: var(--mono-4);
}

/* Grid list styling */
.grid-list {
  display: grid;
  justify-content: start;
  margin: 0;
  list-style-type: none;
}
@media (min-width: 768px) {
  .grid-list {
    grid-column-gap: 16px;
    grid-template-columns: auto auto;
    grid-row-gap: 8px;
  }
}
.grid-list__label {
  grid-column: 1;
  font-weight: 600;
  font-size: 0.88rem;
}
@media (min-width: 768px) {
  .grid-list__label {
    font-size: 1rem;
  }
}
.grid-list__value {
  grid-column: 1;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .grid-list__value {
    grid-column: auto/-1;
    margin-bottom: 0;
  }
}
.grid-list__empty-row {
  grid-column: 1/-1;
  height: 4px;
}
@media (min-width: 768px) {
  .grid-list__empty-row {
    height: 12px;
  }
}

/* Address list styling */
.address-list {
  margin: 0;
  padding: 0;
}
.address-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
  padding: 8px 12px;
  background: var(--list-row-odd);
  list-style-type: none;
}
@media (min-width: 768px) {
  .address-list__item {
    gap: 4px;
    padding: 12px 24px;
  }
}
.address-list__item:nth-child(even) {
  background: var(--list-row-even);
}
.address-list__item * {
  margin: 0;
  padding: 0;
}

/* headings */
.heading {
  grid-column: 1/-1;
  /**
   * Markdown editor elements styling
   */
}
@media (min-width: 768px) {
  .heading {
    max-width: 720px;
  }
}
@media (min-width: 1280px) {
  .heading {
    max-width: 800px;
  }
}
.heading--shadow[data-light-mode] {
  filter: drop-shadow(0 0 32px rgba(255, 255, 255, 0.4));
}
.heading--shadow[data-dark-mode] {
  filter: drop-shadow(0 0 32px rgba(0, 0, 0, 0.8));
}
.heading[data-light-mode] .heading__title, .heading[data-light-mode] .heading__description {
  color: var(--text-color-dark);
}
.heading[data-light-mode] .heading--shadow {
  filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.4));
}
.heading[data-dark-mode] .heading__title, .heading[data-dark-mode] .heading__description {
  color: var(--text-color-light);
}
.heading[data-dark-mode] .heading--shadow {
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.4));
}
.heading__title {
  text-align: inherit;
}
.heading__title__icon {
  font-size: inherit;
}
.heading pre, .heading blockquote {
  width: calc(100% - 48px);
  background: var(--mono-2);
  padding: 16px 24px;
  color: black;
  margin: 8px 0;
  white-space: pre-wrap;
}
.heading pre p:first-child, .heading blockquote p:first-child {
  margin-top: 0;
}
.heading pre li, .heading blockquote li {
  margin: 8px 0 0;
}
@media (min-width: 1024px) {
  .heading pre, .heading blockquote {
    width: 100%;
  }
}
.heading img {
  width: 100%;
}

/* Lists */
.list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
}
@media (min-width: 768px) {
  .list {
    gap: 4px;
  }
}
@media (min-width: 1280px) {
  .list {
    gap: 6px;
  }
}
.list--clean {
  list-style-type: none;
  padding-left: unset;
  text-decoration: none;
}

/**
 * Common styling for images
 * ref: elements/media/image.htm
 */
.picture {
  position: relative;
  display: inline-block;
  border-radius: var(--shape-border-radius);
  overflow: hidden;
  width: 100%;
}

img.image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.image-fallback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.image-fallback--avatar {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background: var(--brand-main);
  fill: var(--brand-main-label);
  font-size: 40px;
}
.image-fallback__icon {
  font-size: 4em;
  max-width: 80%;
  color: black;
  opacity: 0.24;
  transition: color 0.24s, opacity 0.24s;
}

/**
 * Common styling for videos
 * ref: elements/media/video.htm
 */
.video {
  position: relative;
  width: 100%;
}
.video__media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--shape-border-radius);
}

video {
  width: 100%;
}

.table, *:has(> table) {
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  overflow-x: scroll;
  max-width: 100%;
}
.table::-webkit-scrollbar, *:has(> table)::-webkit-scrollbar {
  display: none;
}

[data-dark-mode] table:not(.widget-table) {
  color: var(--text-color-light);
  border: 1px solid var(--mono-8);
}
[data-dark-mode] table:not(.widget-table) thead {
  background: var(--mono-7);
}
[data-dark-mode] table:not(.widget-table) tbody {
  background: var(--mono-9);
}
[data-dark-mode] table:not(.widget-table) tbody tr:nth-child(even) {
  background: var(--mono-8);
}

table:not(.widget-table), [data-light-mode] table:not(.widget-table) {
  width: 100%;
  margin: 16px 0;
  border-spacing: 0;
  overflow: hidden;
  color: var(--text-color-dark);
  border-radius: var(--shape-border-radius);
  border: 1px solid var(--mono-2);
  /* Rounded corners */
}
table:not(.widget-table) thead, [data-light-mode] table:not(.widget-table) thead {
  background: var(--mono-4);
}
table:not(.widget-table) tbody, [data-light-mode] table:not(.widget-table) tbody {
  background: var(--mono-1);
}
table:not(.widget-table) tbody tr:nth-child(even), [data-light-mode] table:not(.widget-table) tbody tr:nth-child(even) {
  background: var(--mono-2);
}
table:not(.widget-table) th, table:not(.widget-table) td, [data-light-mode] table:not(.widget-table) th, [data-light-mode] table:not(.widget-table) td {
  height: 36px;
  vertical-align: middle;
  text-align: left;
  color: inherit;
  padding: 0 12px;
}
@media (min-width: 768px) {
  table:not(.widget-table) th, table:not(.widget-table) td, [data-light-mode] table:not(.widget-table) th, [data-light-mode] table:not(.widget-table) td {
    height: 40px;
  }
}
table:not(.widget-table) th, [data-light-mode] table:not(.widget-table) th {
  font-weight: 700;
  font-style: normal;
}
table:not(.widget-table) thead:last-child:last-child > *:first-child, [data-light-mode] table:not(.widget-table) thead:last-child:last-child > *:first-child {
  border-bottom-left-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) thead:last-child:last-child > *:last-child, [data-light-mode] table:not(.widget-table) thead:last-child:last-child > *:last-child {
  border-bottom-right-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) thead tr:first-child:first-child > *:first-child, [data-light-mode] table:not(.widget-table) thead tr:first-child:first-child > *:first-child {
  border-top-left-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) thead tr:first-child:last-child > *:last-child, [data-light-mode] table:not(.widget-table) thead tr:first-child:last-child > *:last-child {
  border-top-right-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) tbody tr:first-child:first-child > *:first-child, [data-light-mode] table:not(.widget-table) tbody tr:first-child:first-child > *:first-child {
  border-top-left-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) tbody tr:first-child:last-child > *:last-child, [data-light-mode] table:not(.widget-table) tbody tr:first-child:last-child > *:last-child {
  border-top-right-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) tbody tr:last-child > *:first-child, [data-light-mode] table:not(.widget-table) tbody tr:last-child > *:first-child {
  border-bottom-left-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) tbody tr:last-child > *:last-child, [data-light-mode] table:not(.widget-table) tbody tr:last-child > *:last-child {
  border-bottom-right-radius: calc(var(--shape-border-radius) - 1px);
}
table:not(.widget-table) thead + tbody tr:first-child > *:first-child, table:not(.widget-table) thead + tbody tr:first-child > *:last-child, [data-light-mode] table:not(.widget-table) thead + tbody tr:first-child > *:first-child, [data-light-mode] table:not(.widget-table) thead + tbody tr:first-child > *:last-child {
  border-radius: unset !important;
}

.nav-widget-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  padding-left: unset;
  /* Site Picker */
}
.nav-widget-actions .nav-action-button {
  justify-content: center;
  height: 44px;
  min-width: 44px;
  margin: 0;
  padding: 0 8px;
  background: unset;
  color: var(--nav-color);
  border-radius: var(--input-border-radius);
  cursor: pointer;
  box-sizing: border-box;
  /* states */
}
@media (min-width: 768px) {
  .nav-widget-actions .nav-action-button {
    height: 48px;
    min-width: 48px;
  }
}
.nav-widget-actions .nav-action-button--dropdown {
  width: 52px;
  padding: 0;
}
.nav-widget-actions .nav-action-button .icon {
  color: var(--nav-color);
  transition: 0.24s;
  font-size: 1.28rem !important;
}
@media (min-width: 768px) {
  .nav-widget-actions .nav-action-button .icon {
    font-size: 1.4rem !important;
  }
}
.nav-widget-actions .nav-action-button > *:not(.site-picker) {
  transition: background 0.16s cubic-bezier(0.33, 1, 0.68, 1), color 0.16s cubic-bezier(0.33, 1, 0.68, 1);
}
.nav-widget-actions .nav-action-button:focus, .nav-widget-actions .nav-action-button:focus-within {
  background: var(--nav-accent);
  /* Select2 specific element */
}
.nav-widget-actions .nav-action-button:focus .icon, .nav-widget-actions .nav-action-button:focus-within .icon {
  color: var(--nav-accent-color) !important;
}
.nav-widget-actions .nav-action-button:focus .select2-selection, .nav-widget-actions .nav-action-button:focus-within .select2-selection {
  outline: unset;
}
.nav-widget-actions .nav-action-button:focus .select2-selection__arrow b, .nav-widget-actions .nav-action-button:focus-within .select2-selection__arrow b {
  border-top-color: var(--mono-4);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-actions .nav-action-button:hover {
    background: var(--nav-accent);
    /* Select2 specific element */
  }
  .nav-widget-actions .nav-action-button:hover .icon {
    color: var(--nav-accent-color) !important;
  }
  .nav-widget-actions .nav-action-button:hover .select2-selection {
    outline: unset;
  }
  .nav-widget-actions .nav-action-button:hover .select2-selection__arrow b {
    border-top-color: var(--mono-4);
  }
}
.nav-widget-actions .site-picker {
  position: relative;
}
.nav-widget-actions .site-picker select {
  visibility: hidden;
}
.nav-widget-actions .site-picker .select2 {
  width: 100% !important;
}
.nav-widget-actions .site-picker .select2 .selection .select2-selection {
  border: unset;
  background: unset;
  padding: 8px 16px 8px 8px;
}
.nav-widget-actions .site-picker .select2 .selection .select2-selection__rendered .icon {
  font-size: 1.4rem;
}
.nav-widget-actions .site-picker .select2 .selection .select2-selection__rendered .label {
  display: none;
}
.nav-widget-actions .site-picker .select2-selection__arrow b {
  border-top-color: var(--mono-5);
}

.site-picker-dropdown {
  position: fixed;
  top: 64px;
  right: 0;
  width: 320px;
  margin: 0 8px;
  z-index: 920;
  pointer-events: none;
}
@media (min-width: 768px) {
  .site-picker-dropdown {
    top: var(--nav-height-sm);
    width: 400px;
  }
}
@media (min-width: 1024px) {
  .site-picker-dropdown {
    top: var(--nav-height-sm);
  }
}
@media (min-width: 1280px) {
  .site-picker-dropdown {
    top: var(--nav-height-lg);
  }
}
.site-picker-dropdown .select2-container {
  left: unset !important;
  top: 0 !important;
  right: 0;
  min-width: 160px;
  pointer-events: initial;
}
.site-picker-dropdown .select2-container .select2-dropdown {
  border-radius: calc(var(--input-border-radius) / 2);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border: unset;
  background: var(--nav-background);
  color: var(--nav-color);
  overflow: hidden;
}
.site-picker-dropdown .select2-container .select2-dropdown .select2-results__option--selected {
  background: rgba(var(--nav-accent-rgb), 0.24);
}
.site-picker-dropdown .select2-container .select2-dropdown .select2-results__option--highlighted {
  background: var(--nav-accent);
}
@media (min-width: 1024px) {
  .site-picker-dropdown .select2-container .select2-dropdown {
    border-radius: 0 0 calc(var(--input-border-radius) / 2) calc(var(--input-border-radius) / 2);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  }
}
.site-picker-dropdown[data-dark-mode] .select2-dropdown {
  color: var(--nav-accent-color);
}

.nav-widget-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
@media (max-width: 767px) {
  .nav-widget-buttons .button .label {
    display: none;
  }
}

.nav-widget-contact-button {
  border: unset;
  border-radius: unset;
  color: var(--nav-top-bar-accent-color);
  background: var(--nav-top-bar-accent);
}
.nav-widget-contact-button:focus, .nav-widget-contact-button:focus-within {
  background: var(--nav-top-bar-accent-color);
  color: var(--nav-top-bar-accent);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-contact-button:hover {
    background: var(--nav-top-bar-accent-color);
    color: var(--nav-top-bar-accent);
  }
}
.nav-widget-contact-button[data-style=whatsapp] {
  background: #25d366;
  color: #075e54;
}
.nav-widget-contact-button[data-style=whatsapp]:focus, .nav-widget-contact-button[data-style=whatsapp]:focus-within {
  background: #075e54;
  color: #25d366;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-contact-button[data-style=whatsapp]:hover {
    background: #075e54;
    color: #25d366;
  }
}

.nav-widget-contact-options {
  color: var(--nav-top-bar-color);
}
.nav-widget-contact-options .button {
  border-radius: unset;
  color: inherit;
}
.nav-widget-contact-options .button:focus, .nav-widget-contact-options .button:focus-within {
  color: var(--nav-top-bar-accent);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-contact-options .button:hover {
    color: var(--nav-top-bar-accent);
  }
}
.nav-widget-contact-options button.button {
  cursor: initial;
}
.nav-widget-contact-options button.button:focus, .nav-widget-contact-options button.button:focus-within {
  color: inherit;
  background: unset;
  outline: unset;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-contact-options button.button:hover {
    color: inherit;
    background: unset;
    outline: unset;
  }
}

.nav-widget-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  max-width: 80px;
  height: inherit;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .nav-widget-logo {
    max-width: 160px;
  }
}
@media (min-width: 1280px) {
  .nav-widget-logo {
    max-width: 240px;
  }
}
@media (min-width: 1600px) {
  .nav-widget-logo {
    max-width: 280px;
  }
}
.nav-widget-logo:hover, .nav-widget-logo:focus, .nav-widget-logo:focus-within {
  outline: unset;
}
.nav-widget-logo:hover .picture, .nav-widget-logo:focus .picture, .nav-widget-logo:focus-within .picture {
  transform: scale(0.94);
}
.nav-widget-logo .picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: unset;
  object-fit: contain !important;
  overflow: visible;
  transition: transform 0.48s cubic-bezier(0.29, 2.06, 0.5, 0.86);
}
@media (max-width: 767px) {
  .nav-widget-logo .picture {
    max-height: 44px;
  }
}
.nav-widget-logo .picture .image {
  max-width: 100%;
}
@media (min-width: 1280px) {
  .nav-widget-logo .picture[data-logo=sm] {
    display: none;
  }
}
@media (max-width: 1279px) {
  .nav-widget-logo .picture[data-logo=lg] {
    display: none;
  }
}

.nav-widget-tab-bar, .nav-widget-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.nav-widget-tab-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--nav-background);
  height: var(--tab-bar-height-xs);
  padding: 0 8px;
  z-index: 900;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
  border-radius: var(--shape-border-radius) var(--shape-border-radius) 0 0;
}
@media (min-width: 768px) {
  .nav-widget-tab-bar {
    height: var(--tab-bar-height-sm);
    padding: 0 16px;
  }
}
@media (min-width: 1024px) {
  .nav-widget-tab-bar {
    display: none;
  }
}
.nav-widget-tab-bar__tab-menu, .nav-widget-tab-bar__main-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nav-widget-tab-bar__tab-menu .menu-item--submenu-toggle, .nav-widget-tab-bar__main-menu .menu-item--submenu-toggle {
  flex: 0 1 auto;
  min-width: 56px;
}
.nav-widget-tab-bar__tab-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}
@media (min-width: 768px) {
  .nav-widget-tab-bar__tab-menu {
    display: none;
  }
}
.nav-widget-tab-bar__tab-menu .menu-items {
  flex: 1 1 auto;
  gap: 8px;
  overflow: hidden;
  height: 72px;
}
.nav-widget-tab-bar__tab-menu .menu-items:has(+ .menu-item--submenu-toggle) {
  justify-content: start;
}
@media (min-width: 768px) {
  .nav-widget-tab-bar__tab-menu .menu-items {
    gap: 16px;
  }
}
.nav-widget-tab-bar__main-menu {
  gap: 16px;
  justify-content: center;
}
.nav-widget-tab-bar__main-menu:has(.menu-item--submenu-toggle) {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .nav-widget-tab-bar__main-menu {
    display: none;
  }
}
.nav-widget-tab-bar__main-menu .menu-items {
  gap: 16px;
}

.nav-widget-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% + 24px);
  overflow: hidden;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .nav-widget-menu {
    display: none;
  }
}
@media (min-width: 1280px) {
  .nav-widget-menu {
    height: calc(100% + 32px);
  }
}
.nav-widget-menu .menu-item--submenu-toggle {
  height: 100%;
}

.menu-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  flex-wrap: wrap;
  height: 100%;
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 4px;
  flex: 0 0 auto;
  height: inherit;
  margin: unset;
  padding: 12px 4px;
  box-sizing: border-box;
  background: unset;
  color: var(--nav-color);
  border: unset;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.16s cubic-bezier(0.33, 1, 0.68, 1);
  /* Data attributes */
  /* states */
  /* modifiers */
}
@media (min-width: 768px) {
  .menu-item {
    padding: 6px;
    gap: 6px;
  }
}
@media (min-width: 1024px) {
  .menu-item {
    padding: 12px 16px;
  }
}
@media (min-width: 1280px) {
  .menu-item {
    gap: 8px;
  }
}
.menu-item * {
  pointer-events: none;
}
.menu-item__icon {
  font-size: 1.8rem;
  color: inherit;
  transition: color 0.16s cubic-bezier(0.33, 1, 0.68, 1), transform 0.16s cubic-bezier(0.33, 1, 0.68, 1);
}
@media (min-width: 768px) {
  .menu-item__icon {
    font-size: 1.64rem;
  }
}
.menu-item__icon--hidden {
  display: none;
}
.menu-item__label {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
  color: inherit;
  white-space: nowrap;
  transition: color 0.16s cubic-bezier(0.33, 1, 0.68, 1);
}
.menu-item__subitem-indicator {
  position: absolute;
  right: 0;
  color: var(--mono-5);
  font-size: 0.72rem;
}
.menu-item[data-state=active]:not(:hover) .icon {
  color: var(--nav-accent);
}
.menu-item[data-state=selected] {
  background: var(--nav-accent);
}
.menu-item[data-state=selected][data-dark-mode] {
  color: var(--text-color-light);
}
.menu-item:focus, .menu-item:focus-within {
  outline: unset;
  background: var(--nav-accent);
  color: var(--nav-accent-color);
}
.menu-item:focus[data-dark-mode], .menu-item:focus-within[data-dark-mode] {
  color: var(--text-color-light);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .menu-item:hover {
    outline: unset;
    background: var(--nav-accent);
    color: var(--nav-accent-color);
  }
  .menu-item:hover[data-dark-mode] {
    color: var(--text-color-light);
  }
}
.menu-item--subitems {
  padding-right: 16px;
}
.menu-item--horizontal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex: 0 0 48px;
  gap: 8px;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 12px;
}
@media (min-width: 768px) {
  .menu-item--horizontal {
    gap: 12px;
  }
}
.menu-item--horizontal .menu-item__label {
  text-align: left;
  font-size: 0.8em;
}
@media (min-width: 1024px) {
  .menu-item--submenu-toggle .menu-item__icon {
    transform: rotate(180deg);
  }
}
.menu-item[data-menu-state=visible] .menu-item__icon {
  transform: rotate(180deg);
}
@media (min-width: 1024px) {
  .menu-item[data-menu-state=visible] .menu-item__icon {
    transform: unset;
  }
}

.nav-widget-social-media-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: inherit;
  gap: 4px;
}
.nav-widget-social-media-buttons .button {
  padding: 0;
  min-width: unset;
  width: 32px;
  height: inherit;
  border-radius: unset;
}
.nav-widget-social-media-buttons .button:focus, .nav-widget-social-media-buttons .button:focus-within {
  color: var(--nav-top-bar-accent);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .nav-widget-social-media-buttons .button:hover {
    color: var(--nav-top-bar-accent);
  }
}

body:has(.sub-menu[data-position=visible]) {
  overflow: hidden;
}

.sub-menu {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  background: var(--nav-background);
  border-bottom: 1px solid var(--mono-4);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.12);
  transition: transform 0.48s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 880;
  min-height: 280px;
  max-height: 72vh;
}
@media (max-width: 1023px) {
  .sub-menu {
    padding-bottom: var(--tab-bar-height-sm);
    border-top-left-radius: calc(var(--shape-border-radius) / 2);
    border-top-right-radius: calc(var(--shape-border-radius) / 2);
  }
}
@media (max-width: 767px) {
  .sub-menu {
    padding-bottom: var(--tab-bar-height-xs);
  }
}
@media (min-width: 1024px) {
  .sub-menu {
    top: var(--nav-height-sm);
    bottom: unset;
    border-top: 1px solid var(--mono-3);
    border-bottom: unset;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12);
  }
}
@media (min-width: 1280px) {
  .sub-menu {
    top: var(--nav-height-lg);
  }
}
.sub-menu[data-position=hidden] {
  transform: translateY(calc(100% - var(--tab-bar-height-xs)));
}
@media (min-width: 1024px) {
  .sub-menu[data-position=hidden] {
    transform: translateY(-100%);
  }
}
.sub-menu__section {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  position: relative;
  align-self: stretch;
  width: auto;
  padding: 16px;
  gap: 2px;
  min-width: 160px;
  border-right: 1px solid var(--mono-3);
  overflow-x: scroll;
}
.sub-menu__section::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .sub-menu__section {
    padding: 16px 32px;
  }
}
.sub-menu__section:not(:first-child):after {
  position: absolute;
  top: calc(50% - 8px);
  bottom: calc(50% - 8px);
  left: -9px;
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background: var(--nav-background);
  border-right: 1px solid var(--mono-3);
  border-bottom: 1px solid var(--mono-3);
  transform: rotate(-45deg);
}
.sub-menu[data-dark-mode] {
  border-color: var(--mono-6);
}
.sub-menu[data-dark-mode] .sub-menu__section {
  border-color: var(--mono-6);
}
.sub-menu[data-dark-mode] .sub-menu__section:not(:first-child):after {
  border-color: var(--mono-6);
}

/*
 * Page modifiers to fit the navigation menu
 */
body {
  padding-top: var(--nav-height-xs);
}
@media (min-width: 768px) {
  body {
    padding-bottom: 0;
    padding-top: var(--nav-height-sm);
  }
}
@media (min-width: 1280px) {
  body {
    padding-top: var(--nav-height-lg);
  }
}
body[data-no-nav] {
  padding: 0;
}

@media (min-width: 1024px) {
  .stripe-loading-indicator {
    top: var(--nav-height-sm);
  }
}
@media (min-width: 1280px) {
  .stripe-loading-indicator {
    top: var(--nav-height-lg);
  }
}

/*
 * Navigation bar
 */
.navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--nav-height-xs);
  margin: 0;
  background: var(--nav-background);
  border-bottom: var(--main-element-border);
  z-index: 900;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
  transition: transform 0.24s cubic-bezier(0.37, 0, 0.63, 1), 0.24s border-radius cubic-bezier(0.37, 0, 0.63, 1);
  will-change: transform;
}
@media (min-width: 1024px) {
  .navigation {
    width: 100%;
    height: var(--nav-height-sm);
    box-sizing: border-box;
    border-radius: unset;
    transform: unset;
  }
}
@media (min-width: 1280px) {
  .navigation {
    height: var(--nav-height-lg);
  }
}
.navigation__top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--nav-top-bar-background);
  color: var(--nav-top-bar-color);
  width: 100%;
  height: 32px;
}
@media (max-width: 1279px) {
  .navigation__top-bar {
    height: 40px;
  }
}
@media (max-width: 767px) {
  .navigation__top-bar .nav-widget-contact-options [data-contact-option]:not([data-contact-option=phone]):not([data-contact-option=email]) {
    display: none;
  }
  .navigation__top-bar .nav-widget-contact-options:not(:first-child:last-child) [data-contact-option]:not([data-contact-option=phone]) {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .navigation__top-bar .nav-widget-contact-options:not(:first-child:last-child) [data-contact-option=address] {
    display: none;
  }
}
.navigation__top-bar * {
  color: inherit;
  font-size: min(0.8rem, 14px);
}
.navigation__top-bar .button {
  min-height: unset;
  height: inherit;
  padding: 0 8px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .navigation__top-bar .button .icon {
    font-size: 1.4em !important;
  }
}
.navigation__main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  height: 56px;
}
@media (max-width: 1023px) {
  .navigation__main-section .nav-widget-menu {
    display: none;
  }
}
@media (min-width: 768px) {
  .navigation__main-section {
    padding: 12px 16px;
    height: 48px;
    gap: 16px;
  }
}
@media (min-width: 1280px) {
  .navigation__main-section {
    padding: 16px 16px;
    height: 48px;
  }
}
.navigation [data-push-right] {
  margin-left: auto;
}
.navigation [data-separator] {
  position: relative;
  padding-right: 8px;
}
@media (min-width: 768px) {
  .navigation [data-separator] {
    padding-right: 16px;
  }
}
.navigation [data-separator]:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 32px;
  width: 1px;
  background: rgba(0, 0, 0, 0.16);
  margin: auto;
}

.sub-menu + .navigation {
  box-shadow: unset;
}

#navigation-page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  transition: height 0s, opacity 0.48s 0.01s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 840;
}
#navigation-page-overlay[data-position=hidden] {
  opacity: 0;
  height: 0;
  transition: height 0s 0.49s, opacity 0.48s cubic-bezier(0.22, 1, 0.36, 1);
}

.header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  padding: 56px 16px 96px;
  width: 100%;
  background: var(--header-background);
  border-bottom: var(--main-element-border);
}
@media (min-width: 768px) {
  .header {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .header {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1600px) {
  .header {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 768px) {
  .header {
    padding: 80px 40px 120px;
  }
}
@media (min-width: 1600px) {
  .header {
    padding: 160px 64px 200px;
  }
}
.header__copy {
  grid-column: 1/-1;
  position: relative;
  align-self: center;
  z-index: 10;
}
@media (min-width: 768px) {
  .header__copy {
    padding: unset;
  }
}
@media (min-width: 1600px) {
  .header__copy {
    grid-column: 1/span 8;
  }
}
.header__copy__logo {
  border-radius: unset;
}
.header__copy__logo .image {
  width: auto;
  height: auto;
  max-width: 100%;
  /*
   * Logo sizing
   */
}
@media (min-width: ) {
  .header__copy__logo .image[data-logo-height=xs] {
    max-height: 32px;
  }
  .header__copy__logo .image[data-logo-height=sm] {
    max-height: 48px;
  }
  .header__copy__logo .image[data-logo-height=md] {
    max-height: 64px;
  }
  .header__copy__logo .image[data-logo-height=lg] {
    max-height: 128px;
  }
  .header__copy__logo .image[data-logo-height=xl] {
    max-height: 160px;
  }
}
@media (min-width: 768px) {
  .header__copy__logo .image[data-logo-height=xs] {
    max-height: 36px;
  }
  .header__copy__logo .image[data-logo-height=sm] {
    max-height: 56px;
  }
  .header__copy__logo .image[data-logo-height=md] {
    max-height: 80px;
  }
  .header__copy__logo .image[data-logo-height=lg] {
    max-height: 160px;
  }
  .header__copy__logo .image[data-logo-height=xl] {
    max-height: 192px;
  }
}
@media (min-width: 1280px) {
  .header__copy__logo .image[data-logo-height=xs] {
    max-height: 40px;
  }
  .header__copy__logo .image[data-logo-height=sm] {
    max-height: 64px;
  }
  .header__copy__logo .image[data-logo-height=md] {
    max-height: 96px;
  }
  .header__copy__logo .image[data-logo-height=lg] {
    max-height: 192px;
  }
  .header__copy__logo .image[data-logo-height=xl] {
    max-height: 240px;
  }
}
.header__copy__copy {
  margin-top: unset;
}
.header__copy__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .header__copy__actions {
    margin-top: 40px;
  }
}
.header__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.header__background:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(255, 255, 255, 0.56);
}
@media (min-width: 1280px) {
  .header__background:after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.56) 80%, rgba(255, 255, 255, 0) 100%);
  }
}
@media (min-width: 1600px) {
  .header__background:after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.56) 60%, rgba(255, 255, 255, 0) 100%);
  }
}
.header__background__picture, .header__background .video__media {
  height: 100%;
  border-radius: 0;
}
.header[data-dark-mode] .header__background:after {
  background: rgba(0, 0, 0, 0.56);
}
@media (min-width: 1280px) {
  .header[data-dark-mode] .header__background:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 80%, rgba(0, 0, 0, 0) 100%);
  }
}
@media (min-width: 1600px) {
  .header[data-dark-mode] .header__background:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 60%, rgba(0, 0, 0, 0) 100%);
  }
}

.header--showcase {
  padding: 16px 16px 40px;
}
@media (min-width: 768px) {
  .header--showcase {
    padding: 40px 24px;
  }
}
@media (min-width: 1024px) {
  .header--showcase {
    padding: 40px;
  }
}
.header--showcase .header__picture {
  grid-column: 1/-1;
  height: 240px;
  align-self: center;
}
@media (min-width: 768px) {
  .header--showcase .header__picture {
    grid-column: span 5;
    height: 100%;
    max-height: 480px;
  }
}
@media (min-width: 1280px) {
  .header--showcase .header__picture {
    grid-column: span 8;
  }
}
.header--showcase .header__picture .image {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .header--showcase .header__copy {
    grid-column: span 6/-1;
    padding: 24px 0;
    max-width: 640px;
  }
}
@media (min-width: 1280px) {
  .header--showcase .header__copy {
    max-width: 720px;
    grid-column: span 9/-1;
  }
}
.header--showcase .header__copy .heading__title {
  padding-top: 8px;
}
.header--showcase .header__copy__list {
  margin: 16px 0 0;
  color: inherit;
}
.header--showcase .header__copy__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 4px 0 0;
}

.cookie-banner {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  background: var(--notification-background);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  padding: 0 16px calc(var(--nav-height-xs) + 16px);
  border-radius: var(--shape-border-radius) var(--shape-border-radius) 0 0;
  transform: translateX(0);
  transition: transform 0.56s cubic-bezier(0.36, 0, 0.66, -0.56);
  z-index: 850;
}
@media (min-width: 768px) {
  .cookie-banner {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .cookie-banner {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1600px) {
  .cookie-banner {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1920px) {
  .cookie-banner {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 768px) {
  .cookie-banner {
    top: calc(var(--nav-height-sm) + 24px);
    right: 12px;
    bottom: unset;
    left: unset;
    max-width: 480px;
    padding: 0 16px 16px;
    border-radius: var(--shape-border-radius);
  }
}
@media (min-width: 1600px) {
  .cookie-banner {
    top: calc(var(--nav-height-lg) + 24px);
    max-width: 640px;
  }
}
.cookie-banner[data-notification=inactive] {
  bottom: 0;
  transform: translateY(100%);
}
@media (min-width: 768px) {
  .cookie-banner[data-notification=inactive] {
    bottom: unset;
    transform: translateX(600px);
  }
}
@media (min-width: 1600px) {
  .cookie-banner[data-notification=inactive] {
    bottom: unset;
    transform: translateX(800px);
  }
}
.cookie-banner .heading {
  grid-column: 1/-1;
  margin: 16px 0;
}
@media (min-width: 768px) {
  .cookie-banner .heading {
    margin: 0.4rem 0 1.2rem;
  }
}
.cookie-banner .heading__title {
  color: var(--notification-text-color) !important;
  padding-top: 0.2em;
}
.cookie-banner .heading p {
  font-size: 0.88rem;
  margin-top: 4px;
  color: var(--notification-text-color);
}
.cookie-banner__options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/-1;
  gap: 16px;
}
.cookie-banner__options .button {
  flex: 1 1 auto;
}
.cookie-banner__options .button--outline .label {
  color: var(--notification-text-color);
}

.stripe-loading-indicator {
  height: 3px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 890;
}
.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  height: 4px;
  display: block;
  background: var(--brand-main);
  position: absolute;
  -webkit-box-shadow: inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF;
  box-shadow: inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF;
}
.stripe-loading-indicator .stripe {
  width: 100%;
  -webkit-animation: infinite-loader 60s linear;
  animation: infinite-loader 60s linear;
}
.stripe-loading-indicator .stripe-loaded {
  width: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.stripe-loading-indicator.loaded {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.stripe-loading-indicator.loaded .stripe-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  width: 100% !important;
}
.stripe-loading-indicator.hide {
  display: none;
}

/* Infinite loader animation */
@keyframes infinite-loader {
  0% {
    width: 0;
  }
  10% {
    width: 42%;
  }
  20% {
    width: 64%;
  }
  30% {
    width: 80%;
  }
  40% {
    width: 88%;
  }
  50% {
    width: 92%;
  }
  60% {
    width: 94%;
  }
  70% {
    width: 96%;
  }
  80% {
    width: 98%;
  }
  90% {
    width: 99%;
  }
  100% {
    width: 100%;
  }
}
.oc-flash-message {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: fixed;
  top: 8px;
  right: 8px;
  left: 8px;
  margin: 0;
  padding: 0;
  min-height: 48px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  background: var(--notification-background);
  border-radius: var(--shape-border-radius);
  word-break: break-word;
  box-sizing: border-box;
  transform: translateY(-56px);
  overflow: hidden;
  z-index: 1600;
}
@media (min-width: 768px) {
  .oc-flash-message {
    top: 16px;
    right: 16px;
    bottom: unset;
    left: unset;
    width: 440px;
    opacity: 0;
    transform: translateX(64px);
    transition: transform 0.4s, opacity 0.32s;
  }
}
@media (min-width: 1600px) {
  .oc-flash-message {
    right: 24px;
    width: 560px;
  }
}
.oc-flash-message.flash-show {
  transform: translateX(0);
  transition: transform 0.32s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (min-width: 768px) {
  .oc-flash-message.flash-show {
    opacity: 1;
    transition: transform 0.4s, opacity 0.32s;
  }
}
.oc-flash-message.info {
  border: 1px solid var(--state-info);
  background: var(--notification-background);
}
.oc-flash-message.info:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(var(--state-info-rgb), 0.08);
  z-index: 0;
}
.oc-flash-message.info span {
  color: var(--notification-text-color);
}
.oc-flash-message.info a.flash-close:focus:after, .oc-flash-message.info a.flash-close:focus-within:after {
  color: var(--state-info-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .oc-flash-message.info a.flash-close:hover:after {
    color: var(--state-info-active);
  }
}
.oc-flash-message.info a.flash-close:after {
  color: var(--state-info);
}
.oc-flash-message.success {
  border: 1px solid var(--state-success);
  background: var(--notification-background);
}
.oc-flash-message.success:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(var(--state-success-rgb), 0.08);
  z-index: 0;
}
.oc-flash-message.success span {
  color: var(--notification-text-color);
}
.oc-flash-message.success a.flash-close:focus:after, .oc-flash-message.success a.flash-close:focus-within:after {
  color: var(--state-success-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .oc-flash-message.success a.flash-close:hover:after {
    color: var(--state-success-active);
  }
}
.oc-flash-message.success a.flash-close:after {
  color: var(--state-success);
}
.oc-flash-message.warning {
  border: 1px solid var(--state-warning);
  background: var(--notification-background);
}
.oc-flash-message.warning:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(var(--state-warning-rgb), 0.08);
  z-index: 0;
}
.oc-flash-message.warning span {
  color: var(--notification-text-color);
}
.oc-flash-message.warning a.flash-close:focus:after, .oc-flash-message.warning a.flash-close:focus-within:after {
  color: var(--state-warning-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .oc-flash-message.warning a.flash-close:hover:after {
    color: var(--state-warning-active);
  }
}
.oc-flash-message.warning a.flash-close:after {
  color: var(--state-warning);
}
.oc-flash-message.error {
  border: 1px solid var(--state-error);
  background: var(--notification-background);
}
.oc-flash-message.error:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(var(--state-error-rgb), 0.08);
  z-index: 0;
}
.oc-flash-message.error span {
  color: var(--notification-text-color);
}
.oc-flash-message.error a.flash-close:focus:after, .oc-flash-message.error a.flash-close:focus-within:after {
  color: var(--state-error-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .oc-flash-message.error a.flash-close:hover:after {
    color: var(--state-error-active);
  }
}
.oc-flash-message.error a.flash-close:after {
  color: var(--state-error);
}
.oc-flash-message span {
  flex: 1 1 100%;
  padding: 8px 12px;
  color: var(--notification-text-color);
}
.oc-flash-message a.flash-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: initial;
  margin: 0;
  padding: 12px 16px;
  width: 44px;
  height: 44px;
  opacity: 1;
  color: var(--brand-main-label);
  background: unset;
  border: unset;
  border-radius: var(--input-border-radius);
  font-weight: 600;
  font-family: var(--text-font);
  letter-spacing: 0.98px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  transition: background 0.24s, color 0.24s;
  z-index: 1;
}
@media (min-width: 768px) {
  .oc-flash-message a.flash-close {
    width: 48px;
    height: 48px;
  }
}
.oc-flash-message a.flash-close:after {
  content: "×";
  color: var(--notification-text-color);
  font-size: 1.4rem;
}

.page-side-menu, .page-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 0;
  margin: 0;
  padding: 0;
  border: unset;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(0);
  box-sizing: border-box;
  opacity: 0;
  transition: background 400ms cubic-bezier(0.64, 0, 0.78, 0), backdrop-filter 400ms cubic-bezier(0.64, 0, 0.78, 0), height 1ms 400ms, opacity 1ms 400ms, height 1ms 400ms;
  will-change: transform;
}
[data-overlay-state=visible].page-side-menu, [data-overlay-state=visible].page-modal {
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  height: 100%;
  opacity: 1;
  transition: background 400ms 50ms cubic-bezier(0.22, 1, 0.36, 1), backdrop-filter 400ms 50ms cubic-bezier(0.22, 1, 0.36, 1);
}
[data-overlay-state=visible].page-side-menu > *, [data-overlay-state=visible].page-modal > * {
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms 50ms cubic-bezier(0.22, 1, 0.36, 1), opacity 400ms 50ms cubic-bezier(0.22, 1, 0.36, 1);
}
[data-overlay-behind-nav].page-side-menu, [data-overlay-behind-nav].page-modal {
  z-index: 860;
}
@media (max-width: 767px) {
  [data-overlay-behind-nav].page-side-menu, [data-overlay-behind-nav].page-modal {
    border-radius: unset;
  }
}
@media (min-width: 1024px) {
  [data-overlay-behind-nav].page-side-menu, [data-overlay-behind-nav].page-modal {
    top: var(--nav-height-sm);
  }
}
@media (min-width: 1280px) {
  [data-overlay-behind-nav].page-side-menu, [data-overlay-behind-nav].page-modal {
    top: var(--nav-height-lg);
  }
}
[data-overlay-behind-nav][data-overlay-state=visible].page-side-menu, [data-overlay-behind-nav][data-overlay-state=visible].page-modal {
  height: calc(100% - var(--nav-height-xs));
}
@media (min-width: 1024px) {
  [data-overlay-behind-nav][data-overlay-state=visible].page-side-menu, [data-overlay-behind-nav][data-overlay-state=visible].page-modal {
    height: calc(100% - var(--nav-height-sm));
  }
}
@media (min-width: 1280px) {
  [data-overlay-behind-nav][data-overlay-state=visible].page-side-menu, [data-overlay-behind-nav][data-overlay-state=visible].page-modal {
    height: calc(100% - var(--nav-height-lg));
  }
}
.page-side-menu > *, .page-modal > * {
  margin: auto 0 0;
  transform: translateY(100%);
  transition: transform 400ms cubic-bezier(0.64, 0, 0.78, 0), opacity 400ms cubic-bezier(0.64, 0, 0.78, 0);
}
@media (max-width: 767px) {
  .page-side-menu > *, .page-modal > * {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.page-modal {
  display: flex;
  justify-content: center;
  align-items: stretch;
  z-index: 1400;
}
@media (min-width: 768px) {
  .page-modal {
    align-items: center;
  }
}
.page-modal--align-top {
  align-items: flex-start;
}
@media (min-width: 768px) {
  .page-modal > * {
    opacity: 0;
    transform: translateY(64px);
    margin: 24px;
  }
}
@media (min-width: 1024px) {
  .page-modal > * {
    margin: 40px;
  }
}
@media (min-width: 1280px) {
  .page-modal > * {
    margin: 64px;
  }
}

.page-side-menu {
  z-index: 1300;
}
@media (min-width: 768px) {
  .page-side-menu {
    top: 0;
    justify-content: flex-end;
  }
}
.page-side-menu > * {
  border: unset;
  border-radius: unset;
}
@media (min-width: 768px) {
  .page-side-menu > * {
    height: 100%;
    margin-left: auto;
    transform: translateX(100%);
  }
}
@media (min-width: 768px) {
  .page-side-menu[data-overlay-state=visible] {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
  }
  .page-side-menu:not([data-size]) > *, .page-side-menu[data-size=md] > * {
    max-width: 640px;
  }
  .page-side-menu[data-size=sm] > * {
    max-width: 480px;
  }
  .page-side-menu[data-size=lg] {
    right: -800px;
    max-width: 800px;
  }
}

.hldr-account-overlay {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  gap: 16px;
  width: calc(100% - 16px);
  margin: 16px 8px;
  overflow: scroll;
}
.hldr-account-overlay::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .hldr-account-overlay {
    gap: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    margin: 16px;
  }
}
.hldr-account-overlay__form {
  padding: 0;
  margin: 0 0 auto;
}
.hldr-account-overlay__form .input-group:first-child {
  margin-top: 0;
}
.hldr-account-overlay__form .button {
  grid-column: 1/-1;
  justify-self: end;
  margin-top: 24px;
}
.hldr-account-overlay__form__links {
  margin-top: 24px;
  border-top: 1px solid var(--mono-3);
  grid-column: 1/-1;
}
.hldr-account-overlay__banner {
  text-align: center;
  max-width: 448px;
  margin: 0 auto;
}
.hldr-account-overlay__banner__icon {
  color: var(--brand-main);
  font-size: 6em;
  margin: 0.32em 0;
}
.hldr-account-overlay__banner__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin: 2em 0;
}
.hldr-account-overlay__banner__actions .button {
  flex-grow: 1;
}
.hldr-account-overlay__message {
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid var(--mono-3);
}
.hldr-account-overlay__message .button {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .hldr-account-overlay__message .button {
    margin-top: 24px;
  }
}
.hldr-account-overlay__message__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

/*
 * Password reset
 */
.inline-login {
  gap: 16px;
}
.inline-login__logo {
  grid-column: 1/-1;
  max-width: 240px;
  margin: 0 auto 16px;
}
@media (min-width: 768px) {
  .inline-login__logo {
    margin-bottom: 40px;
  }
}
.inline-login__container {
  padding: 16px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  box-sizing: border-box;
}
.inline-login__container__actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}
.inline-login__container form {
  margin: 0;
  padding: 0;
}
.inline-login__container form h1 {
  padding-top: 0;
  grid-column: 1/-1;
  font-size: 1.68em;
}
.inline-login__container form .button {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .inline-login__container form .button {
    margin-top: 24px;
  }
}

/*
 * Account page
 */
.account__quick-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1/-1;
  flex-wrap: wrap;
  gap: 8px;
  margin: 24px 0;
}
@media (min-width: 768px) {
  .account__quick-actions {
    flex-wrap: unset;
  }
}
.account__quick-actions .button {
  flex: 0 0 100%;
}
@media (min-width: 768px) {
  .account__quick-actions .button {
    flex: unset;
  }
}

.hint {
  position: relative;
  grid-column: 1/-1;
  margin: 8px 0;
  border: 1px solid var(--card-border);
  background: rgba(var(--cards-background-rgb), 0.012);
  border-radius: var(--shape-border-radius);
}
.hint:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.hint--info {
  border: 1px solid var(--state-info);
}
.hint--info:before {
  background: rgba(var(--state-info-rgb), 0.012);
}
.hint--info .icon {
  color: var(--state-info);
}
.hint--success {
  border: 1px solid var(--state-success);
}
.hint--success:before {
  background: rgba(var(--state-success-rgb), 0.012);
}
.hint--success .icon {
  color: var(--state-success);
}
.hint--warning {
  border: 1px solid var(--state-warning);
}
.hint--warning:before {
  background: rgba(var(--state-warning-rgb), 0.012);
}
.hint--warning .icon {
  color: var(--state-warning);
}
.hint--error {
  border: 1px solid var(--state-error);
}
.hint--error:before {
  background: rgba(var(--state-error-rgb), 0.012);
}
.hint--error .icon {
  color: var(--state-error);
}
.hint__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px;
}
@media (min-width: 768px) {
  .hint__header {
    padding: 8px 12px;
  }
}
.hint__header__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: unset;
}
.hint__description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
  position: relative;
  padding: 8px;
}
@media (min-width: 768px) {
  .hint__description {
    gap: 8px;
    padding: 8px 12px;
  }
}
.hint__description .icon {
  margin: 4px 0 0;
  font-size: 24px;
}

.banner {
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  padding: 16px;
}
.banner--main {
  background: rgba(var(--button-main-rgb), 0.04);
  border: 1px solid var(--button-main);
}
.banner--accent {
  background: rgba(var(--button-accent-rgb), 0.04);
  border: 1px solid var(--button-accent);
}
.banner--info {
  background: rgba(var(--state-info-rgb), 0.04);
  border: 1px solid var(--state-info);
}
.banner--success {
  background: rgba(var(--state-success-rgb), 0.04);
  border: 1px solid var(--state-success);
}
.banner--warning {
  background: rgba(var(--state-warning-rgb), 0.04);
  border: 1px solid var(--state-warning);
}
.banner--error {
  background: rgba(var(--state-error-rgb), 0.04);
  border: 1px solid var(--state-error);
}
.banner .heading__title {
  padding-top: unset;
}
.banner__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.banner__actions .button {
  flex: 1 1 auto;
}

.footer {
  background: var(--footer-background);
  color: var(--text-color-light);
  width: 100%;
}
.footer *:not(.button--solid) {
  color: inherit;
}
.footer__top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  gap: 40px;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgba(0, 0, 0, 0.24);
}
@media (min-width: 768px) {
  .footer__top-bar {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .footer__top-bar {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1280px) {
  .footer__top-bar {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (min-width: 768px) {
  .footer__top-bar {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.footer__main-section {
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  align-items: start;
  margin: 24px 0;
}
@media (min-width: 768px) {
  .footer__main-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .footer__main-section {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1280px) {
  .footer__main-section {
    padding-left: 64px;
    padding-right: 64px;
  }
}
.footer__main-section *:not(.button--solid) {
  color: inherit;
}
@media (min-width: 768px) {
  .footer__main-section {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    margin: 40px 0;
  }
}
@media (min-width: 1280px) {
  .footer__main-section {
    grid-template-columns: unset;
    grid-auto-flow: column;
    justify-content: start;
    gap: 32px;
  }
}
@media (min-width: 1600px) {
  .footer__main-section {
    gap: 64px;
  }
}
.footer__main-section__column {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 768px) {
  .footer__main-section__column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (min-width: 1280px) {
  .footer__main-section__column {
    grid-auto-flow: column;
    align-items: start;
  }
  .footer__main-section__column > * {
    max-width: 360px;
  }
}
.footer [data-separator] {
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .footer [data-separator] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    width: 100%;
    padding-bottom: 24px;
  }
}
@media (min-width: 1280px) {
  .footer [data-separator] {
    border-right: 1px solid rgba(255, 255, 255, 0.16);
    height: 100%;
    padding-right: 32px;
  }
}
.footer [data-column] {
  position: relative;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .footer [data-column] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    padding-bottom: 24px;
  }
  .footer [data-column]:after {
    position: absolute;
    top: 0;
    right: -12px;
    bottom: 12px;
    content: "";
    width: 1px;
    background: rgba(255, 255, 255, 0.16);
  }
  .footer [data-column]:nth-of-type(even):after {
    content: unset;
  }
  .footer [data-column]:nth-of-type(n+3):after {
    top: -12px;
  }
  .footer [data-column]:last-of-type, .footer [data-column]:nth-last-of-type(2):not(:nth-of-type(even)) {
    border-bottom: unset;
    padding-bottom: unset;
  }
  .footer [data-column] + [data-column]:nth-of-type(even) {
    padding-left: 12px;
  }
}
.footer .widget-title, .footer .heading__title {
  padding-top: unset;
  color: inherit;
}
.footer .heading {
  margin-top: 0;
}
.footer .widget-list, .footer .widget-icon-list {
  padding-left: unset;
  list-style-type: none;
}
.footer .widget-list li, .footer .widget-icon-list li {
  line-height: 1.6em;
}
.footer .widget-list li a, .footer .widget-list li span, .footer .widget-icon-list li a, .footer .widget-icon-list li span {
  display: inline-block;
  padding: 4px 0;
}
.footer .widget-list li a, .footer .widget-icon-list li a {
  margin: 0;
  color: inherit;
  text-decoration-color: transparent;
}
.footer .widget-list li a:focus, .footer .widget-list li a:focus-within, .footer .widget-icon-list li a:focus, .footer .widget-icon-list li a:focus-within {
  color: var(--footer-accent);
  text-decoration-color: var(--footer-accent);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .footer .widget-list li a:hover, .footer .widget-icon-list li a:hover {
    color: var(--footer-accent);
    text-decoration-color: var(--footer-accent);
  }
}
.footer .widget-list li .widget-list, .footer .widget-icon-list li .widget-list {
  margin-top: 8px;
  padding-left: 12px;
}
.footer .widget-icon-list li {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
}
.footer .widget-icon-list li .icon {
  font-size: 1.2em;
}
.footer .widget-table {
  margin: 1rem 0 0;
  border-collapse: collapse;
  white-space: nowrap;
}
.footer .widget-table tbody {
  text-align: left;
}
.footer .widget-table tbody th, .footer .widget-table tbody td {
  padding: 4px 0;
}
.footer .widget-table tbody tr:first-child th, .footer .widget-table tbody tr:first-child td {
  padding-top: unset;
}
.footer .widget-table tbody th {
  font-style: normal;
  font-weight: 600;
  padding-right: 24px;
}

@font-face {
  font-family: "Zona Pro Subset";
  src: url("/themes/helder-helder/assets/fonts/zonapro-regular-subset.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
.footer-brand-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: calc(32px + var(--tab-bar-height-xs));
  background: rgba(0, 0, 0, 0.32);
  text-align: center;
}
@media (min-width: 768px) {
  .footer-brand-bar {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .footer-brand-bar {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1280px) {
  .footer-brand-bar {
    padding-left: 64px;
    padding-right: 64px;
  }
}
.footer-brand-bar:not([data-dark-mode]) {
  background: rgba(255, 255, 255, 0.32);
}
@media (min-width: 768px) {
  .footer-brand-bar {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: calc(16px + var(--tab-bar-height-sm));
  }
}
@media (min-width: 1024px) {
  .footer-brand-bar {
    padding-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .footer-brand-bar {
    gap: 16px;
  }
}
.footer-brand-bar__copyright {
  flex: 0 0 auto;
  margin: 0;
}
.footer-brand-bar .helder-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 0 0 auto;
  width: auto;
  margin: 0;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
  font-family: "Zona Pro Subset", sans-serif;
  transition: transform 0.32s cubic-bezier(0.29, 2.06, 0.5, 0.86);
}
.footer-brand-bar .helder-badge:focus, .footer-brand-bar .helder-badge:focus-within {
  transform: scale(0.92);
  color: inherit;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .footer-brand-bar .helder-badge:hover {
    transform: scale(0.92);
    color: inherit;
  }
}
@media (min-width: 768px) {
  .footer-brand-bar .helder-badge {
    margin-left: auto;
  }
}
.footer-brand-bar .helder-badge__label {
  white-space: nowrap;
  font-family: inherit;
  font-size: 0.92em;
}
.footer-brand-bar .helder-badge__image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: unset;
}
.footer-brand-bar .helder-badge__image .image {
  height: 22px;
  object-fit: contain;
}

.footer-widget-badges {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 24px;
}
@media (max-width: 767px) {
  .footer-widget-badges {
    flex-wrap: wrap;
  }
}
.footer-widget-badges[href] {
  cursor: pointer;
}
.footer-widget-badges[href]:focus .picture, .footer-widget-badges[href]:focus-within .picture {
  transform: scale(0.92);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .footer-widget-badges[href]:hover .picture {
    transform: scale(0.92);
  }
}
.footer-widget-badges[data-monochrome] .picture {
  filter: grayscale(1);
  opacity: 0.64;
}
.footer-widget-badges[data-monochrome] [href] .picture {
  transition: filter 0.24s, opacity 0.24s, transform 0.24s;
}
.footer-widget-badges[data-monochrome] [href] .picture:focus, .footer-widget-badges[data-monochrome] [href] .picture:focus-within {
  filter: grayscale(0);
  opacity: 1;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .footer-widget-badges[data-monochrome] [href] .picture:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}
.footer-widget-badges__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  max-width: 128px;
  margin-top: unset;
}
.footer-widget-badges__badge .picture {
  max-height: inherit;
  border-radius: unset;
}
.footer-widget-badges__badge .picture .image {
  max-height: inherit;
  object-fit: contain;
}

@media (min-width: 1280px) {
  .footer-widget-link-list {
    max-width: unset;
  }
}
@media (min-width: 768px) {
  .footer-widget-link-list__lists {
    display: grid;
    grid-auto-flow: column;
    gap: 24px;
  }
}
@media (min-width: 1600px) {
  .footer-widget-link-list__lists {
    gap: 64px;
  }
}
@media (min-width: 1280px) {
  .footer-widget-link-list__lists .widget-list {
    max-width: 320px;
  }
}
.footer-widget-link-list__lists .widget-list li a {
  padding: 0;
}

.footer-widget-logo .picture {
  border-radius: unset;
  width: auto;
  height: auto;
  max-height: 200px;
  max-width: 200px;
}

.footer-widget-phone-number {
  color: inherit !important;
  text-decoration: none !important;
  margin: 0;
  transition: color 0.24s cubic-bezier(0.33, 1, 0.68, 1) !important;
}
.footer-widget-phone-number:focus, .footer-widget-phone-number:focus-within {
  color: var(--footer-accent) !important;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .footer-widget-phone-number:hover {
    color: var(--footer-accent) !important;
  }
}
.footer-widget-phone-number__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: unset;
}

.footer-widget-social-media-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.footer-widget-social-media-buttons .button {
  border-color: rgb(var(--mono-0-rgb), 0.48);
  padding: 0;
}
.footer-widget-social-media-buttons .button .icon {
  font-size: 1.5em !important;
}

.footer-widget-text-block {
  display: grid;
  justify-items: start;
  gap: 24px;
}

/**
 * Element visibility
 */
[data-visibility] {
  transition: opacity 0.24s, filter 0.24s;
}

[data-visibility=hidden] {
  visibility: hidden;
}

[data-visibility=visible] {
  visibility: visible;
}

[data-visibility=disabled] {
  opacity: 0.24;
  filter: grayscale(1);
  pointer-events: none;
}

[data-display=hidden] {
  display: none;
}

/**
 * Hide elements on specific breakpoints
 */
@media (max-width: 373px) {
  [data-bp-hide*=xs] {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  [data-bp-hide*=sm] {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  [data-bp-hide*=md] {
    display: none;
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  [data-bp-hide*=lg] {
    display: none;
  }
}

@media (min-width: 1600px) and (max-width: 1919px) {
  [data-bp-hide*=xl] {
    display: none;
  }
}

@media (min-width: 1920px) {
  [data-bp-hide*=xxl] {
    display: none;
  }
}

/*
 * Logo sizing
 */
@media (min-width: ) {
  [data-logo-height=xs] img {
    max-height: 24px;
  }
  [data-logo-height=sm] img {
    max-height: 32px;
  }
  [data-logo-height=md] img {
    max-height: 40px;
  }
  [data-logo-height=lg] img {
    max-height: 48px;
  }
  [data-logo-height=xl] img {
    max-height: 56px;
  }
}
@media (min-width: 768px) {
  [data-logo-height=xs] img {
    max-height: 32px;
  }
  [data-logo-height=sm] img {
    max-height: 40px;
  }
  [data-logo-height=md] img {
    max-height: 48px;
  }
  [data-logo-height=lg] img {
    max-height: 56px;
  }
  [data-logo-height=xl] img {
    max-height: 64px;
  }
}
@media (min-width: 1280px) {
  [data-logo-height=xs] img {
    max-height: 40px;
  }
  [data-logo-height=sm] img {
    max-height: 48px;
  }
  [data-logo-height=md] img {
    max-height: 56px;
  }
  [data-logo-height=lg] img {
    max-height: 64px;
  }
  [data-logo-height=xl] img {
    max-height: 72px;
  }
}
/*
 * Image positioning
 */
[data-alignment="0,0"] {
  object-position: 0% 0%;
}

[data-alignment="0,25"] {
  object-position: 0% 25%;
}

[data-alignment="0,50"] {
  object-position: 0% 50%;
}

[data-alignment="0,75"] {
  object-position: 0% 75%;
}

[data-alignment="0,100"] {
  object-position: 0% 100%;
}

[data-alignment="25,0"] {
  object-position: 25% 0%;
}

[data-alignment="25,25"] {
  object-position: 25% 25%;
}

[data-alignment="25,50"] {
  object-position: 25% 50%;
}

[data-alignment="25,75"] {
  object-position: 25% 75%;
}

[data-alignment="25,100"] {
  object-position: 25% 100%;
}

[data-alignment="50,0"] {
  object-position: 50% 0%;
}

[data-alignment="50,25"] {
  object-position: 50% 25%;
}

[data-alignment="50,50"] {
  object-position: 50% 50%;
}

[data-alignment="50,75"] {
  object-position: 50% 75%;
}

[data-alignment="50,100"] {
  object-position: 50% 100%;
}

[data-alignment="75,0"] {
  object-position: 75% 0%;
}

[data-alignment="75,25"] {
  object-position: 75% 25%;
}

[data-alignment="75,50"] {
  object-position: 75% 50%;
}

[data-alignment="75,75"] {
  object-position: 75% 75%;
}

[data-alignment="75,100"] {
  object-position: 75% 100%;
}

[data-alignment="100,0"] {
  object-position: 100% 0%;
}

[data-alignment="100,25"] {
  object-position: 100% 25%;
}

[data-alignment="100,50"] {
  object-position: 100% 50%;
}

[data-alignment="100,75"] {
  object-position: 100% 75%;
}

[data-alignment="100,100"] {
  object-position: 100% 100%;
}

@media (min-width: 768px) {
  [data-alignment-lg="0,0"] {
    object-position: 0% 0%;
  }
  [data-alignment-lg="0,25"] {
    object-position: 0% 25%;
  }
  [data-alignment-lg="0,50"] {
    object-position: 0% 50%;
  }
  [data-alignment-lg="0,75"] {
    object-position: 0% 75%;
  }
  [data-alignment-lg="0,100"] {
    object-position: 0% 100%;
  }
  [data-alignment-lg="25,0"] {
    object-position: 25% 0%;
  }
  [data-alignment-lg="25,25"] {
    object-position: 25% 25%;
  }
  [data-alignment-lg="25,50"] {
    object-position: 25% 50%;
  }
  [data-alignment-lg="25,75"] {
    object-position: 25% 75%;
  }
  [data-alignment-lg="25,100"] {
    object-position: 25% 100%;
  }
  [data-alignment-lg="50,0"] {
    object-position: 50% 0%;
  }
  [data-alignment-lg="50,25"] {
    object-position: 50% 25%;
  }
  [data-alignment-lg="50,50"] {
    object-position: 50% 50%;
  }
  [data-alignment-lg="50,75"] {
    object-position: 50% 75%;
  }
  [data-alignment-lg="50,100"] {
    object-position: 50% 100%;
  }
  [data-alignment-lg="75,0"] {
    object-position: 75% 0%;
  }
  [data-alignment-lg="75,25"] {
    object-position: 75% 25%;
  }
  [data-alignment-lg="75,50"] {
    object-position: 75% 50%;
  }
  [data-alignment-lg="75,75"] {
    object-position: 75% 75%;
  }
  [data-alignment-lg="75,100"] {
    object-position: 75% 100%;
  }
  [data-alignment-lg="100,0"] {
    object-position: 100% 0%;
  }
  [data-alignment-lg="100,25"] {
    object-position: 100% 25%;
  }
  [data-alignment-lg="100,50"] {
    object-position: 100% 50%;
  }
  [data-alignment-lg="100,75"] {
    object-position: 100% 75%;
  }
  [data-alignment-lg="100,100"] {
    object-position: 100% 100%;
  }
}
/*
 * Media object ratios
 */
.picture[data-ratio="1"], .video[data-ratio="1"] {
  aspect-ratio: 1;
}
.picture[data-ratio="2"], .video[data-ratio="2"] {
  aspect-ratio: 2;
}
.picture[data-ratio="3"], .video[data-ratio="3"] {
  aspect-ratio: 3;
}
.picture[data-ratio="4"], .video[data-ratio="4"] {
  aspect-ratio: 4;
}
.picture[data-ratio="5"], .video[data-ratio="5"] {
  aspect-ratio: 5;
}
.picture[data-ratio="6"], .video[data-ratio="6"] {
  aspect-ratio: 6;
}

/*
 * Image fit
 */
[data-image-fit], [data-image-fit=cover] {
  object-fit: cover;
}

[data-image-fit=contain] {
  object-fit: contain;
}

/* Grid children sizes */
[data-grid-size="1/1"] {
  grid-column: 1/-1;
}

[data-grid-size="1/2"] {
  grid-column: span 4;
}

@media (min-width: 768px) {
  [data-grid-size="1/2"] {
    grid-column: span 6;
  }
  [data-grid-size-lg="1/1"] {
    grid-column: 1/-1;
  }
  [data-grid-size-lg="1/2"] {
    grid-column: span 6;
  }
  [data-grid-size-lg="1/3"] {
    grid-column: span 4;
  }
  [data-grid-size-lg="2/3"] {
    grid-column: span 8;
  }
}
@media (min-width: 1600px) {
  [data-grid-size-lg="1/2"] {
    grid-column: span 9;
  }
  [data-grid-size-lg="2/3"] {
    grid-column: span 12;
  }
  [data-grid-size-lg="1/3"] {
    grid-column: span 6;
  }
  [data-grid-size-xl="1/1"] {
    grid-column: 1/-1;
  }
  [data-grid-size-xl="2/3"] {
    grid-column: span 12;
  }
  [data-grid-size-xl="1/2"] {
    grid-column: span 9;
  }
  [data-grid-size-xl="1/3"] {
    grid-column: span 6;
  }
}
/* alignment for grid children */
[data-grid-alignment=end] {
  grid-column-end: -1;
}

/*
 * Icon and text color modifiers
 */
[data-color-brand-main] {
  color: var(--brand-main);
}

[data-color-brand-accent] {
  color: var(--brand-accent);
}

[data-color-state-info] {
  color: var(--state-info);
}

[data-color-state-success] {
  color: var(--state-success);
}

[data-color-state-warning] {
  color: var(--state-warning);
}

[data-color-state-error] {
  color: var(--state-error);
}

/* Shadows */
[data-shadow] {
  box-shadow: var(--element-shadow);
}

/* Text alignment */
[data-text-align=left] {
  text-align: left;
}

[data-text-align=center] {
  text-align: center;
}

[data-text-align=right] {
  text-align: right;
}

.scroll-handler-container {
  grid-column: 1/-1;
  margin: 0 -16px;
  position: relative;
  max-width: 100vw;
}
@media (min-width: 768px) {
  .scroll-handler-container {
    margin: 0 -24px;
  }
}
@media (min-width: 1024px) {
  .scroll-handler-container {
    margin: 0 -40px;
  }
}
.scroll-handler-container__slider {
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  width: 100%;
  padding: 8px 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  user-select: none;
  gap: 8px;
  transition: opacity 0.8s;
}
.scroll-handler-container__slider::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .scroll-handler-container__slider {
    gap: 16px;
  }
}
@media (min-width: 1280px) {
  .scroll-handler-container__slider {
    mask-image: linear-gradient(to right, transparent, black 48px, black calc(100% - 48px), transparent);
  }
}
.scroll-handler-container__slider:before, .scroll-handler-container__slider:after {
  content: "";
  flex: 0 0 8px;
}
@media (min-width: 768px) {
  .scroll-handler-container__slider:before, .scroll-handler-container__slider:after {
    flex: 0 0 8px;
  }
}
@media (min-width: 1024px) {
  .scroll-handler-container__slider:before, .scroll-handler-container__slider:after {
    flex: 0 0 24px;
  }
}
@media (min-width: 1280px) {
  .scroll-handler-container__slider:before, .scroll-handler-container__slider:after {
    flex: 0 0 24px;
  }
}
.scroll-handler-container__slider[data-fade-out] {
  opacity: 0;
}
.scroll-handler-container__slider > * {
  scroll-snap-align: start;
  scroll-margin: 16px;
}
@media (min-width: 768px) {
  .scroll-handler-container__slider > * {
    scroll-margin: 24px;
  }
}
@media (min-width: 1024px) {
  .scroll-handler-container__slider > * {
    scroll-margin: 40px;
  }
}
@media (min-width: 1280px) {
  .scroll-handler-container__slider > * {
    scroll-margin: 64px;
  }
}
.scroll-handler-container__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/-1;
  pointer-events: none;
}
@media only screen and (hover: none) and (pointer: coarse) {
  .scroll-handler-container__buttons {
    display: none;
  }
}
.scroll-handler-container__buttons .button {
  min-height: 44px;
  min-width: 44px;
  pointer-events: initial;
}

* + .scroll-handler-container {
  margin-top: 16px;
}
@media (min-width: 768px) {
  * + .scroll-handler-container {
    margin-top: 24px;
  }
}
@media (min-width: 1024px) {
  * + .scroll-handler-container {
    margin-top: 24px;
  }
}

.phpdebugbar {
  pointer-events: initial !important;
}
.phpdebugbar * {
  font-size: 14px !important;
}
.phpdebugbar .phpdebugbar-drag-capture {
  display: none !important;
}

.phpdebugbar-panel:not(.phpdebugbar-active) {
  display: none;
}

.phpdebugbar-panel.phpdebugbar-active {
  display: block !important;
}