@use '../../../../../../resources/scss/mixins' as m;

.footer-widget-link-list {

  @include m.bp(lg) {
    max-width: unset;
  }

  &__lists {

    @include m.bp(sm) {
      display: grid;
      grid-auto-flow: column;
      gap: 24px;
    }

    @include m.bp(xl) {
      gap: 64px;
    }

    .widget-list {
      @include m.bp(lg) {
        max-width: 320px;
      }

      li a {
        padding: 0;
      }
    }
  }
}