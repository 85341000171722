@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

body:has(.sub-menu[data-position='visible']) {
  overflow: hidden;
}

.sub-menu {
  @include m.position(fixed, null, 0, 0, 0);
  @include m.flex(stretch, start);
  background: var(--nav-background);
  border-bottom: 1px solid var(--mono-4);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.12);
  transition: transform 0.48s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: v.$z-nav-sub-menu;
  min-height: 280px;
  max-height: 72vh;

  @include m.bp(null, md) {
    padding-bottom: var(--tab-bar-height-sm);
    border-top-left-radius: calc(var(--shape-border-radius) / 2);
    border-top-right-radius: calc(var(--shape-border-radius) / 2);
  }

  @include m.bp(null, sm) {
    padding-bottom: var(--tab-bar-height-xs);
  }

  @include m.bp(md) {
    top: var(--nav-height-sm);
    bottom: unset;
    border-top: 1px solid var(--mono-3);
    border-bottom: unset;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12);
  }

  @include m.bp(lg) {
    top: var(--nav-height-lg);
  }

  &[data-position='hidden'] {
    transform: translateY(#{calc(100% - var(--tab-bar-height-xs))});

    @include m.bp(md) {
      transform: translateY(-100%);
    }
  }

  &__section {
    @include m.flex(start, stretch, column);
    @include m.hide-scrollbars;
    position: relative;
    align-self: stretch;
    width: auto;
    padding: 16px;
    gap: 2px;
    min-width: 160px;
    border-right: 1px solid var(--mono-3);
    overflow-x: scroll;

    @include m.bp(sm) {
      padding: 16px 32px;
    }

    &:not(:first-child):after {
      @include m.position(absolute, calc(50% - 8px), null, calc(50% - 8px), -9px);
      display: block;
      content: '';
      width: 16px;
      height: 16px;
      background: var(--nav-background);
      border-right: 1px solid var(--mono-3);
      border-bottom: 1px solid var(--mono-3);
      transform: rotate(-45deg);
    }
  }

  &[data-dark-mode] {
    border-color: var(--mono-6);
  }

  &[data-dark-mode] & {
    &__section {
      border-color: var(--mono-6);

      &:not(:first-child):after {
        border-color: var(--mono-6);
      }
    }
  }
}