@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

@forward 'navigation/actions';
@forward 'navigation/buttons';
@forward 'navigation/contact-button';
@forward 'navigation/contact-options';
@forward 'navigation/logo';
@forward 'navigation/menu';
@forward 'navigation/menu-item';
@forward 'navigation/social-media-buttons';
@forward 'navigation/sub-menu';


/*
 * Page modifiers to fit the navigation menu
 */

body {
  padding-top: var(--nav-height-xs);

  @include m.bp(sm) {
    padding-bottom: 0;
    padding-top: var(--nav-height-sm);
  }

  @include m.bp(lg) {
    padding-top: var(--nav-height-lg);
  }

  &[data-no-nav] {
    padding: 0;
  }
}

.stripe-loading-indicator {
  @include m.bp(md) {
    top: var(--nav-height-sm);
  }

  @include m.bp(lg) {
    top: var(--nav-height-lg);
  }
}

/*
 * Navigation bar
 */

.navigation {
  @include m.position(fixed, 0, 0, null, 0);
  height: var(--nav-height-xs);
  margin: 0;
  background: var(--nav-background);
  border-bottom: var(--main-element-border);
  z-index: v.$z-nav-menu;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
  transition: transform 0.24s cubic-bezier(0.37, 0, 0.63, 1), 0.24s border-radius cubic-bezier(0.37, 0, 0.63, 1);
  will-change: transform;

  @include m.bp(md) {
    width: 100%;
    height: var(--nav-height-sm);
    box-sizing: border-box;
    border-radius: unset;
    transform: unset;
  }

  @include m.bp(lg) {
    height: var(--nav-height-lg);
  }

  &__top-bar {
    @include m.flex(between, center);
    background: var(--nav-top-bar-background);
    color: var(--nav-top-bar-color);
    width: 100%;
    height: 32px;

    @include m.bp(null, lg) {
      height: 40px;
    }

    // Adaptive widget: hide some options on smaller screens
    .nav-widget-contact-options {

      @include m.bp(null, sm) {

        // Show phone and email if this is the only widget
        [data-contact-option]:not([data-contact-option="phone"]):not([data-contact-option="email"]) {
          display: none;
        }

        // Show phone if other widgets are available
        &:not(:first-child:last-child) [data-contact-option]:not([data-contact-option="phone"]) {
          display: none;
        }
      }

      @include m.bp(sm, md) {
        &:not(:first-child:last-child) [data-contact-option='address'] {
          display: none;
        }
      }
    }

    * {
      color: inherit;
      font-size: min(0.8rem, 14px);
    }

    .button {
      min-height: unset;
      height: inherit;
      padding: 0 8px;
      font-weight: v.$font-semi-bold;

      @include m.bp(null, sm) {
        .icon {
          font-size: 1.4em !important;
        }
      }
    }
  }

  &__main-section {
    @include m.flex(between, center);
    gap: 8px;
    padding: 8px;
    height: 56px;

    // Adaptive widgets
    @include m.bp(null, md) {
      .nav-widget-menu {
        display: none;
      }
    }

    @include m.bp(sm) {
      padding: 12px 16px;
      height: 48px;
      gap: 16px;
    }

    @include m.bp(lg) {
      padding: 16px 16px;
      height: 48px;
    }
  }

  // Widget styling
  [data-push-right] {
    margin-left: auto;
  }

  [data-separator] {
    position: relative;
    padding-right: 8px;

    @include m.bp(sm) {
      padding-right: 16px;
    }

    &:after {
      @include m.position(absolute, 0, 0, 0);
      content: '';
      height: 32px;
      width: 1px;
      background: rgba(0, 0, 0, 0.16);
      margin: auto;
    }
  }
}

.sub-menu + .navigation {
  box-shadow: unset;
}

#navigation-page-overlay {
  @include m.position(fixed, 0, 0, 0, 0);
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  transition: height 0s, opacity 0.48s 0.01s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: v.$z-nav-page-overlay;

  &[data-position='hidden'] {
    opacity: 0;
    height: 0;
    transition: height 0s 0.49s, opacity 0.48s cubic-bezier(0.22, 1, 0.36, 1);
  }
}