@use '../../../../../../resources/scss/mixins' as m;

.footer-widget-phone-number {
  color: inherit !important;
  text-decoration: none !important;
  margin: 0;
  transition: color 0.24s cubic-bezier(0.33, 1, 0.68, 1) !important;

  @include m.interaction() {
    color: var(--footer-accent) !important;
  }

  &__label {
    @include m.flex(start, center);
    gap: 12px;
    padding: unset;
  }
}