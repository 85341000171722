@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/*
 * CSS Reset
 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1;
}


/*
 * HTML and Body styling
 */

:root {
  font-size: calc(var(--text-size) * 14px);

  @include m.bp(xl) {
    font-size: calc(var(--text-size) * 16px);
  }
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  @include m.flex(center, start, column);
  color: var(--text-color-dark);
  background: var(--body-color);
  min-height: 100vh;
  box-sizing: border-box;
  transition: opacity v.$overlay-transition-time v.$overlay-transition-out,
  transform v.$overlay-transition-time v.$overlay-transition-out,
  visibility 1ms v.$overlay-transition-time;

  &[data-dark-mode] {
    color: var(--text-color-light);
  }

  &[data-lock-body='true'] {
    overflow: hidden;
  }

  &[data-active-overlay] {
    overflow: hidden;
  }
}

#page-header, #page-content {
  flex: 1 1 100%;
  width: 100%;
}

main, #content {
  @include m.flex(start, center, column);
  flex: 1 1 100%;
  width: 100%;
  scroll-margin: 24px;

  @include m.bp(sm) {
    scroll-margin: 80px;
  }

  @include m.bp(xl) {
    scroll-margin: 128px;
  }
}

/*
 * Accessibility and highlighting
 */

::selection {
  background: var(--brand-main);
  color: var(--brand-main-label);
}

:focus {
  outline: 1px solid var(--mono-2);
  outline-offset: 4px;
}


/*
 * Sections
 * - Grid setup
 * - Section and form setup
 */

section, form {
  @include m.grid;
  position: relative;
  width: 100%;
  margin: var(--section-padding) 0;
  padding: 0 map-get(v.$section-padding-sizes, xs);
  scroll-margin: 16px;

  @include m.bp(sm) {
    padding: 0 map-get(v.$section-padding-sizes, sm);
    scroll-margin: 128px;
  }

  @include m.bp(md) {
    padding: 0 map-get(v.$section-padding-sizes, md);
  }

  @include m.bp(xl) {
    margin: calc(var(--section-padding) * 1.4) 0;
    padding: 0 map-get(v.$section-padding-sizes, lg);
  }

  /* Modifier for full width pages and full width / background blocks */
  body[data-page-width="1fr"] &, &[data-background], &.full-width {
    padding: 0;

    @include m.bp(sm) {
      padding: 0;
    }

    @include m.bp(xl) {
      padding: 0;
    }
  }

  /* Modifiers for different margin / padding variants */
  &[data-background] {
    padding: 0;
    width: calc(100% - map-get(v.$section-padding-sizes, xs) * 2);
    margin: var(--section-padding) (map-get(v.$section-padding-sizes, xs) * -1);
    border-radius: var(--shape-border-radius);

    @include m.bp(sm) {
      padding: 0;
      width: calc(100% - map-get(v.$section-padding-sizes, sm) * 2);
      margin: var(--section-padding) (map-get(v.$section-padding-sizes, sm) * -1);
    }

    @include m.bp(md) {
      padding: 0;
      width: calc(100% - map-get(v.$section-padding-sizes, md) * 2);
      margin: var(--section-padding) (map-get(v.$section-padding-sizes, md) * -1);
    }

    @include m.bp(xl) {
      padding: 0;
      width: calc(100% - map-get(v.$section-padding-sizes, lg) * 2);
      margin: var(--section-padding) (map-get(v.$section-padding-sizes, lg) * -1);
    }
  }

  &.error {
    @include m.flex(center, center, column);
    grid-column: 1 / -1;
    flex: 1 1 100%;
    width: 100%;
    margin: 128px auto;

    > .icon {
      font-size: 4rem;
      color: var(--brand-main);
      animation: pulse 2.4s cubic-bezier(0.72, 0.08, 0.28, 0.92) infinite;
    }

    &__message {
      text-align: center;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.24;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.24;
  }
}