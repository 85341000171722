@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.banner {
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  padding: 16px;

  @include m.generateThemeColors() {
    &--#{m.$gtc-color} {
      background: rgba(var(--#{m.$gtc-type}-#{m.$gtc-color}-rgb), 0.04);
      border: 1px solid var(--#{m.$gtc-type}-#{m.$gtc-color});
    }
  }

  .heading__title {
    padding-top: unset;
  }

  &__actions {
    @include m.flex(start, center);
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;

    .button {
      flex: 1 1 auto;
    }
  }
}