@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;
@use '../fonts';

.footer {
  background: var(--footer-background);
  color: var(--text-color-light);
  width: 100%;

  *:not(.button--solid) {
    color: inherit;
  }

  &__top-bar {
    @include m.flex(center, center, column);
    @include m.section-padding-sizes();
    gap: 40px;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    background: rgba(0, 0, 0, 0.24);

    @include m.bp(sm) {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  &__main-section {
    @include m.section-padding-sizes();
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    align-items: start;
    margin: 24px 0;

    *:not(.button--solid) {
      color: inherit;
    }

    @include m.bp(sm) {
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      margin: 40px 0;
    }

    @include m.bp(lg) {
      grid-template-columns: unset;
      grid-auto-flow: column;
      justify-content: start;
      gap: 32px;
    }

    @include m.bp(xl) {
      gap: 64px;
    }

    &__column {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;

      @include m.bp(sm) {
        @include m.flex(start, start, column);
      }

      @include m.bp(lg) {
        grid-auto-flow: column;
        align-items: start;

        > * {
          max-width: 360px;
        }
      }
    }
  }

  // Widget elements
  [data-separator] {
    box-sizing: border-box;

    @include m.bp(null, lg) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      width: 100%;
      padding-bottom: 24px;
    }

    @include m.bp(lg) {
      border-right: 1px solid rgba(255, 255, 255, 0.16);
      height: 100%;
      padding-right: 32px;
    }
  }

  [data-column] {
    position: relative;

    @include m.bp(sm, lg) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      padding-bottom: 24px;

      &:after {
        @include m.position(absolute, 0, -12px, 12px);
        content: '';
        width: 1px;
        background: rgba(255, 255, 255, 0.16);
      }

      &:nth-of-type(even):after {
        content: unset;
      }

      &:nth-of-type(n+3):after {
        top: -12px;
      }

      &:last-of-type, &:nth-last-of-type(2):not(:nth-of-type(even)) {
        border-bottom: unset;
        padding-bottom: unset;
      }

      + [data-column]:nth-of-type(even) {
        padding-left: 12px;
      }
    }
  }

  .widget-title, .heading__title {
    @extend h4;
    padding-top: unset;
    color: inherit;
  }

  .heading {
    margin-top: 0;
  }

  .widget-list, .widget-icon-list {
    padding-left: unset;
    list-style-type: none;

    li {
      line-height: 1.6em;

      a, span {
        display: inline-block;
        padding: 4px 0;
      }

      a {
        margin: 0;
        color: inherit;
        text-decoration-color: transparent;

        @include m.interaction() {
          color: var(--footer-accent);
          text-decoration-color: var(--footer-accent);
        }
      }

      .widget-list {
        margin-top: 8px;
        padding-left: 12px;
      }
    }
  }

  .widget-icon-list li {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    align-items: center;

    .icon {
      font-size: 1.2em;
    }
  }

  .widget-table {
    margin: 1rem 0 0;
    border-collapse: collapse;
    white-space: nowrap;

    tbody {
      text-align: left;

      th, td {
        padding: 4px 0;
      }

      tr:first-child {
        th, td {
          padding-top: unset;
        }
      }

      th {
        font-style: normal;
        font-weight: v.$font-semi-bold;
        padding-right: 24px;
      }
    }
  }
}