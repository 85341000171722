@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;
@use 'section';

.hldr-account-overlay {
  @include m.flex(between, start, column);
  @include m.hide-scrollbars;
  gap: 16px;
  width: calc(100% - 16px);
  margin: 16px 8px;
  overflow: scroll;

  @include m.bp(sm) {
    gap: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    margin: 16px;
  }

  &__form {
    padding: 0;
    margin: 0 0 auto;

    .input-group:first-child {
      margin-top: 0;
    }

    .button {
      grid-column: 1 / -1;
      justify-self: end;
      margin-top: 24px;
    }

    &__links {
      margin-top: 24px;
      border-top: 1px solid var(--mono-3);
      grid-column: 1 / -1;
    }
  }

  &__banner {
    text-align: center;
    max-width: 448px;
    margin: 0 auto;

    &__icon {
      color: var(--brand-main);
      font-size: 6em;
      margin: 0.32em 0;
    }

    &__actions {
      @include m.flex(between, center);
      gap: 8px;
      flex-wrap: wrap;
      margin: 2em 0;

      .button {
        flex-grow: 1;
      }
    }
  }

  &__message {
    width: 100%;
    margin-top: 16px;
    border-top: 1px solid var(--mono-3);

    .button {
      margin-top: 16px;

      @include m.bp(sm) {
        margin-top: 24px;
      }
    }

    &__actions {
      @include m.flex(between, center);
      gap: 16px;
    }
  }
}

/*
 * Password reset
 */

.inline-login {
  gap: 16px;

  &__logo {
    grid-column: 1 / -1;
    max-width: 240px;
    margin: 0 auto 16px;

    @include m.bp(sm) {
      margin-bottom: 40px;
    }
  }

  &__container {
    @extend %section;
    padding: 16px;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    box-sizing: border-box;

    &__actions {
      @include m.flex(start, start, column);
      gap: 8px;
    }

    form {
      margin: 0;
      padding: 0;

      h1 {
        padding-top: 0;
        grid-column: 1 / -1;
        font-size: 1.68em;
      }

      .button {
        margin-top: 16px;

        @include m.bp(sm) {
          margin-top: 24px;
        }
      }
    }
  }
}

/*
 * Account page
 */

.account {

  &__quick-actions {
    @include m.flex(start, center);
    grid-column: 1 / -1;
    flex-wrap: wrap;
    gap: 8px;
    margin: 24px 0;

    @include m.bp(sm) {
      flex-wrap: unset;
    }

    .button {
      flex: 0 0 100%;

      @include m.bp(sm) {
        flex: unset;
      }
    }
  }
}

