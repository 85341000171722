@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/**
 * Element visibility
 */
[data-visibility] {
  transition: opacity 0.24s, filter 0.24s;
}

[data-visibility='hidden'] {
  visibility: hidden;
}

[data-visibility='visible'] {
  visibility: visible;
}

[data-visibility='disabled'] {
  opacity: 0.24;
  filter: grayscale(1);
  pointer-events: none;
}

[data-display='hidden'] {
  display: none;
}

/**
 * Hide elements on specific breakpoints
 */
[data-bp-hide*='xs'] {
  @include m.bp(null, xs) {
    display: none;
  }
}

[data-bp-hide*='sm'] {
  @include m.bp(sm, md) {
    display: none;
  }
}

[data-bp-hide*='md'] {
  @include m.bp(md, lg) {
    display: none;
  }
}

[data-bp-hide*='lg'] {
  @include m.bp(lg, xl) {
    display: none;
  }
}

[data-bp-hide*='xl'] {
  @include m.bp(xl, xxl) {
    display: none;
  }
}

[data-bp-hide*='xxl'] {
  @include m.bp(xxl) {
    display: none;
  }
}

/*
 * Logo sizing
 */
@each $bp, $sizes in v.$logo-sizes {

  @include m.bp($bp) {
    @each $size, $height in $sizes {
      [data-logo-height=#{$size}] img {
        max-height: $height;
      }
    }
  }
}

/*
 * Image positioning
 */
@each $xPos in v.$alignment-positions {
  @each $yPos in v.$alignment-positions {
    [data-alignment="#{$xPos},#{$yPos}"] {
      object-position: $xPos + #{'%'} $yPos + #{'%'};
    }
  }
}

@include m.bp(sm) {
  @each $xPos in v.$alignment-positions {
    @each $yPos in v.$alignment-positions {
      [data-alignment-lg="#{$xPos},#{$yPos}"] {
        object-position: $xPos + #{'%'} $yPos + #{'%'};
      }
    }
  }
}

/*
 * Media object ratios
 */

.picture, .video {

  @for $size from 1 through 6 {

    &[data-ratio="#{$size}"] {
      aspect-ratio: $size;
    }
  }
}

/*
 * Image fit
 */
[data-image-fit], [data-image-fit="cover"] {
  object-fit: cover;
}

[data-image-fit="contain"] {
  object-fit: contain;
}

/* Grid children sizes */
[data-grid-size='1/1'] {
  grid-column: 1 / -1;
}

[data-grid-size='1/2'] {
  grid-column: span 4;
}

@include m.bp(sm) {
  [data-grid-size='1/2'] {
    grid-column: span 6;
  }

  [data-grid-size-lg='1/1'] {
    grid-column: 1 / -1;
  }

  [data-grid-size-lg='1/2'] {
    grid-column: span 6;
  }

  [data-grid-size-lg='1/3'] {
    grid-column: span 4;
  }

  [data-grid-size-lg='2/3'] {
    grid-column: span 8;
  }
}

@include m.bp(xl) {
  [data-grid-size-lg='1/2'] {
    grid-column: span 9;
  }

  [data-grid-size-lg='2/3'] {
    grid-column: span 12;
  }

  [data-grid-size-lg='1/3'] {
    grid-column: span 6;
  }

  [data-grid-size-xl='1/1'] {
    grid-column: 1 / -1;
  }

  [data-grid-size-xl='2/3'] {
    grid-column: span 12;
  }

  [data-grid-size-xl='1/2'] {
    grid-column: span 9;
  }

  [data-grid-size-xl='1/3'] {
    grid-column: span 6;
  }
}

/* alignment for grid children */
[data-grid-alignment='end'] {
  grid-column-end: -1;
}

/*
 * Icon and text color modifiers
 */
[data-color-brand-main] {
  color: var(--brand-main)
}

;
[data-color-brand-accent] {
  color: var(--brand-accent)
}

;

[data-color-state-info] {
  color: var(--state-info)
}

;
[data-color-state-success] {
  color: var(--state-success)
}

;
[data-color-state-warning] {
  color: var(--state-warning)
}

;
[data-color-state-error] {
  color: var(--state-error)
}

;

/* Shadows */
[data-shadow] {
  box-shadow: var(--element-shadow);
}

/* Text alignment */
[data-text-align='left'] {
  text-align: left;
}

[data-text-align='center'] {
  text-align: center;
}

[data-text-align='right'] {
  text-align: right;
}