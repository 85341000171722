@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.stripe-loading-indicator {
  height: 3px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: v.$z-stripe-loading-indicator;

  .stripe, .stripe-loaded {
    height: 4px;
    display: block;
    background: var(--brand-main);
    position: absolute;
    -webkit-box-shadow: inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF;
    box-shadow: inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF;
  }

  .stripe {
    width: 100%;
    -webkit-animation: infinite-loader 60s linear;
    animation: infinite-loader 60s linear;
  }

  .stripe-loaded {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  &.loaded {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s linear;
    transition: opacity 0.4s linear;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;

    .stripe-loaded {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transition: width 0.3s linear;
      transition: width 0.3s linear;
      width: 100% !important;
    }
  }

  &.hide {
    display: none;
  }
}

/* Infinite loader animation */
@keyframes infinite-loader {
  0% {
    width: 0;
  }
  10% {
    width: 42%;
  }
  20% {
    width: 64%;
  }
  30% {
    width: 80%;
  }
  40% {
    width: 88%;
  }
  50% {
    width: 92%;
  }
  60% {
    width: 94%;
  }
  70% {
    width: 96%;
  }
  80% {
    width: 98%;
  }
  90% {
    width: 99%;
  }
  100% {
    width: 100%;
  }
}