@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;
@use '_variables' as nav;

.menu-item {
  @include m.flex(center, center, column);
  position: relative;
  gap: 4px;
  flex: 0 0 auto;
  height: inherit;
  margin: unset;
  padding: 12px 4px;
  box-sizing: border-box;
  background: unset;
  color: var(--nav-color);
  border: unset;
  text-decoration: none;
  cursor: pointer;
  transition: background nav.$menu-item-transition;

  @include m.bp(sm) {
    padding: 6px;
    gap: 6px;
  }

  @include m.bp(md) {
    padding: 12px 16px;
  }

  @include m.bp(lg) {
    gap: 8px;
  }

  * {
    pointer-events: none;
  }

  &__icon {
    font-size: 1.8rem;
    color: inherit;
    transition: color nav.$menu-item-transition, transform nav.$menu-item-transition;

    @include m.bp(sm) {
      font-size: 1.64rem
    }

    &--hidden {
      display: none;
    }
  }

  &__label {
    display: inline-block;
    width: 100%;
    font-weight: v.$font-semi-bold;
    font-size: 0.8rem;
    text-align: center;
    color: inherit;
    white-space: nowrap;
    transition: color nav.$menu-item-transition;
  }

  &__subitem-indicator {
    @include m.position(absolute, null, 0);
    color: var(--mono-5);
    font-size: 0.72rem;
  }

  /* Data attributes */
  &[data-state='active']:not(:hover) .icon {
    color: var(--nav-accent);
  }

  &[data-state='selected'] {
    background: var(--nav-accent);

    &[data-dark-mode] {
      color: var(--text-color-light);
    }
  }

  /* states */
  @include m.interaction() {
    outline: unset;
    background: var(--nav-accent);
    color: var(--nav-accent-color);

    &[data-dark-mode] {
      color: var(--text-color-light);
    }
  }

  /* modifiers */
  &--subitems {
    padding-right: 16px;
  }

  &--horizontal {
    @include m.flex(start, center, row);
    flex: 0 0 48px;
    gap: 8px;
    width: 100%;
    height: 44px;
    margin: 0;
    padding: 12px;

    @include m.bp(sm) {
      gap: 12px;
    }
  }

  &--horizontal &__label {
    text-align: left;
    font-size: 0.8em;
  }

  @include m.bp(md) {
    &--submenu-toggle &__icon {
      transform: rotate(180deg);
    }
  }

  &[data-menu-state='visible'] &__icon {
    transform: rotate(180deg);

    @include m.bp(md) {
      transform: unset;
    }
  }
}