@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.oc-flash-message {
  @include m.flex(stretch, center);
  @include m.position(fixed, 8px, 8px, null, 8px);
  margin: 0;
  padding: 0;
  min-height: 48px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  background: var(--notification-background);
  border-radius: var(--shape-border-radius);
  word-break: break-word;
  box-sizing: border-box;
  transform: translateY(-56px);
  overflow: hidden;
  z-index: v.$z-notification;

  @include m.bp(sm) {
    top: 16px;
    right: 16px;
    bottom: unset;
    left: unset;
    width: 440px;
    opacity: 0;
    transform: translateX(64px);
    transition: transform 0.4s, opacity 0.32s;
  }

  @include m.bp(xl) {
    right: 24px;
    width: 560px;
  }

  &.flash-show {
    transform: translateX(0);
    transition: transform 0.32s cubic-bezier(0.22, 1, 0.36, 1);

    @include m.bp(sm) {
      opacity: 1;
      transition: transform 0.4s, opacity 0.32s;
    }
  }

  @include m.generateThemeColors('state') {
    &.#{m.$gtc-color} {
      border: 1px solid var(--state-#{m.$gtc-color});
      background: var(--notification-background);

      &:after {
        @include m.position(absolute, 0, 0, 0, 0);
        content: '';
        background: rgba(var(--state-#{m.$gtc-color}-rgb), 0.08);
        z-index: 0;
      }

      span {
        color: var(--notification-text-color);
      }

      a.flash-close {

        @include m.interaction() {

          &:after {
            color: var(--state-#{m.$gtc-color}-active);
          }
        }

        &:after {
          color: var(--state-#{m.$gtc-color});
        }
      }
    }
  }

  span {
    flex: 1 1 100%;
    padding: 8px 12px;
    color: var(--notification-text-color);
  }

  a.flash-close {
    @include m.flex(center, center);
    position: initial;
    margin: 0;
    padding: 12px 16px;
    width: 44px;
    height: 44px;
    opacity: 1;
    color: var(--brand-main-label);
    background: unset;
    border: unset;
    border-radius: var(--input-border-radius);
    font-weight: v.$font-semi-bold;
    font-family: var(--text-font);
    letter-spacing: 0.98px;
    line-height: 24px;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    transition: background 0.24s, color 0.24s;
    z-index: 1;

    @include m.bp(sm) {
      width: 48px;
      height: 48px;
    }

    &:after {
      content: '\00D7';
      color: var(--notification-text-color);
      font-size: 1.4rem;
    }
  }
}