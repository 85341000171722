@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/* headings */
.heading {
  grid-column: 1 / -1;

  @include m.bp(sm) {
    max-width: 720px;
  }

  @include m.bp(lg) {
    max-width: 800px;
  }

  &--shadow {
    &[data-light-mode] {
      filter: drop-shadow(0 0 32px rgba(255, 255, 255, 0.4));
    }

    &[data-dark-mode] {
      filter: drop-shadow(0 0 32px rgba(0, 0, 0, 0.8));
    }
  }

  &[data-light-mode] & {
    &__title, &__description {
      color: var(--text-color-dark);
    }

    &--shadow {
      filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.4));
    }
  }

  &[data-dark-mode] & {
    &__title, &__description {
      color: var(--text-color-light);
    }

    &--shadow {
      filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.4));
    }
  }

  &__title {
    text-align: inherit;

    &__icon {
      font-size: inherit;
    }
  }

  /**
   * Markdown editor elements styling
   */
  // pre (code) and blockquote
  pre, blockquote {
    width: calc(100% - 48px);
    background: var(--mono-2);
    padding: 16px 24px;
    color: black;
    margin: 8px 0;
    white-space: pre-wrap;

    p:first-child {
      margin-top: 0;
    }

    li {
      margin: 8px 0 0;
    }

    @include m.bp(md) {
      width: 100%;
    }
  }

  img {
    width: 100%;
  }
}

/* Lists */
.list {
  @include m.flex(start, start, column);
  gap: 2px;

  @include m.bp(sm) {
    gap: 4px;
  }

  @include m.bp(lg) {
    gap: 6px;
  }

  &--clean {
    list-style-type: none;
    padding-left: unset;
    text-decoration: none;
  }
}