@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;
@use 'inputs/buttons';

%section {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 0;
  margin-top: 16px;
  background: var(--section-background);
  border: 1px solid var(--section-border);
  border-radius: var(--shape-border-radius);
  overflow: hidden;
  grid-column: 1 / -1;
  max-height: stretch;

  .section {
    max-height: unset;
  }

  @include m.bp(sm) {
    margin-top: 24px;
  }
}

.section {
  @extend %section;

  &:not([data-grid-size]) {
    @include m.bp(sm) {
      min-width: 480px;
    }
  }

  &__header {
    @include m.flex(between, center);
    flex: 1 0 auto;
    gap: 8px;
    grid-column: 1 / -1;
    padding-left: 10px;
    border-bottom: 1px solid var(--section-border);
    min-height: 44px;

    @include m.bp(sm) {
      padding-left: 16px;
      min-height: 52px;
    }

    &:last-child {
      border-bottom: unset;
    }

    &__heading {
      @include m.flex(start, center);
      gap: 12px;
      flex: 1 1 auto;
      overflow: hidden;
      max-width: unset;

      &__icon {
        color: var(--brand-main);
        font-size: 1.28em;
      }

      &__title {
        font-size: 1rem;
        padding: 0;
        margin: 8px 0;
        overflow: hidden;
      }
    }

    &__quick-action {
      @include m.flex(center, center);
      height: 100%;
      border-left: 1px solid var(--section-border);

      .button {
        @extend %button;
        @extend .button--main;
        @extend .button--outline;
        flex: 0 0 auto;
        border: unset;
        border-radius: unset;
        align-self: stretch;

        @include m.interaction() {
          border-radius: unset;
        }
      }
    }
  }

  &--copy &__content {
    padding: 0 12px 16px;

    @include m.bp(sm) {
      padding: 0 16px 24px;
    }

    @include m.bp(lg) {
      padding: 0 24px 24px;
    }
  }

  &__content {
    overflow-y: auto;
    max-height: 100%;

    &__heading {
      padding: 0 12px 8px;

      @include m.bp(sm) {
        padding: 0 16px 8px;
      }
    }

    .form {
      padding: 4px 12px 16px;

      @include m.bp(sm) {
        padding: 4px 16px 24px;
      }
    }

    > .form-group {
      margin: 0;
      padding: 0 12px 16px;

      @include m.bp(sm) {
        padding: 0 16px 16px;
      }
    }

    > .list, > .grid-list {
      padding: 16px 12px 4px;

      @include m.bp(sm) {
        padding: 24px;
      }
    }
  }

  &__actions {
    @include m.flex(between, center);
    flex-wrap: wrap;
    gap: 8px;
    padding: 12px;
    border-top: 1px solid var(--section-border);

    @include m.bp(null, sm) {
      .button {
        flex: 1 1 auto;
      }
    }

    @include m.bp(sm) {
      gap: 16px;

      > *:last-child {
        margin-left: auto;
      }
    }

    .footnote {
      margin: auto;
      padding: unset;
      color: var(--mono-7);
      font-size: 0.88rem;
      text-align: center;
    }
  }
}

.section-wrapper {
  @include m.grid();
  grid-column: 1 / -1;
  gap: 16px;
  padding: 0;

  @include m.bp(sm) {
    gap: 24px;
  }
}

.section-button {
  @extend %section;
  @include m.flex(start, center);
  gap: 8px;
  height: 48px;
  padding: 0 10px;
  cursor: pointer;
  transition: v.$button-transition;

  @include m.bp(sm) {
    gap: 12px;
    height: 56px;
    padding: 0 16px;
  }

  @include m.interaction {
    background: rgba(var(--brand-accent-rgb), 0.08);
    border: 1px solid var(--brand-accent);
  }

  &__icon {
    color: var(--brand-accent);
    font-size: 1.28rem;
  }

  &__label {
    color: var(--text-color-dark);
    font-weight: v.$font-semi-bold;
  }
}