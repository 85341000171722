@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

/**
 * Common styling for images
 * ref: elements/media/image.htm
 */
.picture {
  position: relative;
  display: inline-block;
  border-radius: var(--shape-border-radius);
  overflow: hidden;
  width: 100%;
}

img.image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.image-fallback {
  @include m.position(absolute, 0, 0, 0, 0);
  @include m.flex(center, center);
  width: 100%;
  height: 100%;

  &--avatar {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    background: var(--brand-main);
    fill: var(--brand-main-label);
    font-size: 40px;
  }

  &__icon {
    font-size: 4em;
    max-width: 80%;
    color: black;
    opacity: 0.24;
    transition: v.$fallback-image-transition;
  }
}

/**
 * Common styling for videos
 * ref: elements/media/video.htm
 */
.video {
  position: relative;
  width: 100%;

  &__media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--shape-border-radius);
  }
}

video {
  width: 100%;
}