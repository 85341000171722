@use '../../../../../../resources/scss/mixins' as m;

.footer-widget-badges {
  @include m.flex(center, stretch);
  gap: 24px;

  @include m.bp(null, sm) {
    flex-wrap: wrap;
  }

  &[href] {
    cursor: pointer;

    @include m.interaction() {
      .picture {
        transform: scale(0.92);
      }
    }
  }

  &[data-monochrome] .picture {
    filter: grayscale(1);
    opacity: 0.64;
  }

  &[data-monochrome] [href] .picture {
    transition: filter 0.24s, opacity 0.24s, transform 0.24s;

    @include m.interaction() {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  &__badge {
    @include m.flex(center, center);
    max-height: 48px;
    max-width: 128px;
    margin-top: unset;

    .picture {
      max-height: inherit;
      border-radius: unset;

      .image {
        max-height: inherit;
        object-fit: contain;
      }
    }
  }
}