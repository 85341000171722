@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-social-media-buttons {
  @include m.flex(start, center);
  height: inherit;
  gap: 4px;

  .button {
    padding: 0;
    min-width: unset;
    width: 32px;
    height: inherit;
    border-radius: unset;

    @include m.interaction() {
      color: var(--nav-top-bar-accent);
    }
  }
}