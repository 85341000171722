@use '../../../../../../resources/scss/variables' as v;
@use '../../../../../../resources/scss/mixins' as m;

/*
 * This file is based on Select2 v4.0.0
 * Source: https://select2.org/
 */

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  width: 100%;
  position: relative;
  vertical-align: middle;

  @include m.interaction() {
    cursor: pointer;
  }

  @import 'single';
  @import 'multiple';
}

@import 'dropdown';

.select2 .selection {
  display: block;

  .select2-selection {
    @include m.flex(start, center);
    width: 100%;
    height: 44px;
    padding: 8px 12px;
    background: var(--input-field-background);
    color: var(--input-field-color);
    border: 1px solid var(--input-field-border);
    border-radius: var(--input-border-radius);
    box-sizing: border-box;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@import 'layout';