@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-tab-bar, .nav-widget-menu {
  @include m.flex(between, center);
  box-sizing: border-box;
}

.nav-widget-tab-bar {
  @include m.position(fixed, null, 0, 0, 0);
  width: 100%;
  background: var(--nav-background);
  height: var(--tab-bar-height-xs);
  padding: 0 8px;
  z-index: v.$z-nav-menu;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
  border-radius: var(--shape-border-radius) var(--shape-border-radius) 0 0;

  @include m.bp(sm) {
    height: var(--tab-bar-height-sm);
    padding: 0 16px;
  }

  @include m.bp(md) {
    display: none;
  }

  &__tab-menu, &__main-menu {
    @include m.flex(between, center);
    width: 100%;

    .menu-item--submenu-toggle {
      flex: 0 1 auto;
      min-width: 56px;
    }
  }

  &__tab-menu {
    @include m.flex(between, center);
    gap: 4px;

    @include m.bp(sm) {
      display: none;
    }

    .menu-items {
      flex: 1 1 auto;
      gap: 8px;
      overflow: hidden;
      height: 72px;

      &:has(+ .menu-item--submenu-toggle) {
        justify-content: start;
      }

      @include m.bp(sm) {
        gap: 16px;
      }
    }
  }

  &__main-menu {
    gap: 16px;
    justify-content: center;

    &:has(.menu-item--submenu-toggle) {
      justify-content: space-between;
    }

    @include m.bp(null, sm) {
      display: none;
    }

    .menu-items {
      gap: 16px;
    }
  }

}

.nav-widget-menu {
  @include m.flex(start, start);
  height: calc(100% + 24px);
  overflow: hidden;
  box-sizing: border-box;

  @include m.bp(null, sm) {
    display: none;
  }

  @include m.bp(lg) {
    height: calc(100% + 32px);
  }

  .menu-item--submenu-toggle {
    height: 100%;
  }
}

.menu-items {
  @include m.flex(between, center);
  flex: 0 1 auto;
  flex-wrap: wrap;
  height: 100%;
}