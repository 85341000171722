@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;
@use 'fields';

/* Prefixes and suffixes */
.prefix, .suffix {
  @extend %input;
  @include m.flex(center, center);
  width: auto;
  min-width: unset;
  line-height: unset;
  color: var(--mono-7);
}

.prefix {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;

  &:not(.button) {
    border-right: unset;
  }
}

.suffix {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;

  &:not(.button) {
    border-left: unset;
  }
}

.input {

  &--prefix, &--suffix {
    flex: 1;
    width: 100%;
  }

  &--prefix {
    border-left: unset;
    padding-left: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  &--suffix {
    border-right: unset;
    padding-right: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
}