@use '../../../../resources/scss/variables' as v;
@use '../../../../resources/scss/mixins' as m;

.scroll-handler-container {
  grid-column: 1 / -1;
  margin: 0 -#{ map-get(v.$section-padding-sizes, xs)};
  position: relative;
  max-width: 100vw;

  @include m.bp(sm) {
    margin: 0 -#{ map-get(v.$section-padding-sizes, sm)};
  }

  @include m.bp(md) {
    margin: 0 -#{ map-get(v.$section-padding-sizes, md)};
  }

  &__slider {
    @include m.hide-scrollbars();
    width: 100%;
    padding: 8px 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    user-select: none;
    gap: 8px;
    transition: opacity 0.8s;

    @include m.bp(sm) {
      gap: 16px;
    }

    @include m.bp(lg) {
      mask-image: linear-gradient(to right, transparent, black 48px, black calc(100% - 48px), transparent);
    }

    &:before, &:after {
      content: '';
      flex: 0 0 #{ map-get(v.$section-padding-sizes, xs) - 8px};

      @include m.bp(sm) {
        flex: 0 0 #{ map-get(v.$section-padding-sizes, sm) - 16px};
      }

      @include m.bp(md) {
        flex: 0 0 #{ map-get(v.$section-padding-sizes, md) - 16px};
      }

      @include m.bp(lg) {
        flex: 0 0 #{ map-get(v.$section-padding-sizes, lg) - 40px};
      }
    }

    &[data-fade-out] {
      opacity: 0;
    }

    > * {
      scroll-snap-align: start;
      scroll-margin: map-get(v.$section-padding-sizes, xs);

      @include m.bp(sm) {
        scroll-margin: map-get(v.$section-padding-sizes, sm);
      }

      @include m.bp(md) {
        scroll-margin: map-get(v.$section-padding-sizes, md);
      }

      @include m.bp(lg) {
        scroll-margin: map-get(v.$section-padding-sizes, lg);
      }
    }
  }

  &__buttons {
    @include m.flex(between, center);
    grid-column: 1 / -1;
    pointer-events: none;

    @include m.inputMethod(touch) {
      display: none;
    }

    .button {
      min-height: 44px;
      min-width: 44px;
      pointer-events: initial;
    }
  }
}

* + .scroll-handler-container {
  margin-top: 16px;

  @include m.bp(sm) {
    margin-top: 24px;
  }

  @include m.bp(md) {
    margin-top: 24px;
  }
}