@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-contact-options {
  color: var(--nav-top-bar-color);

  .button {
    border-radius: unset;
    color: inherit;

    @include m.interaction() {
      color: var(--nav-top-bar-accent);
    }
  }

  button.button {
    cursor: initial;

    @include m.interaction() {
      color: inherit;
      background: unset;
      outline: unset;
    }
  }
}