@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.nav-widget-logo {
  @include m.flex(start, center);
  flex: 0 0 auto;
  max-width: 80px;
  height: inherit;
  margin-top: 0;

  @include m.bp(md) {
    max-width: 160px;
  }

  @include m.bp(lg) {
    max-width: 240px;
  }

  @include m.bp(xl) {
    max-width: 280px;
  }

  &:hover, &:focus, &:focus-within {
    outline: unset;

    .picture {
      transform: scale(0.94);
    }
  }

  .picture {
    @include m.flex(center, center);
    width: 100%;
    height: 100%;
    border-radius: unset;
    object-fit: contain !important;
    overflow: visible;
    transition: transform 0.48s cubic-bezier(0.29, 2.06, 0.5, 0.86);

    @include m.bp(null, sm) {
      max-height: 44px;
    }

    .image {
      max-width: 100%;
    }

    @include m.bp(lg) {
      &[data-logo="sm"] {
        display: none;
      }
    }

    @include m.bp(null, lg) {
      &[data-logo="lg"] {
        display: none;
      }
    }
  }
}